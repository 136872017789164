import React, { useContext } from 'react';
import './styles.scss';
import addSpaces from '../../../../utils/addSpaces';
import { getColumnTotal } from '../../utils/getTotal';
import { getShownMalls, getShownStores } from '../../utils/getShown';
import { TableContext } from '../Table/TableContext';
import { getDiffPercentage } from '../../utils/getDiff';
import dayjs from 'dayjs';

// Sort columns from oldest to most recent (left to right)
const sortColumnsByDate = (columns) => {
  return [...columns].sort((a, b) => {
    if (a.isDiff || b.isDiff) return 0;
    const dateA = dayjs(a.from);
    const dateB = dayjs(b.from);
    return dateA.valueOf() - dateB.valueOf(); // Oldest first
  });
};

function TableTotal() {
  const { shownColumns, tableSettings, isEdit, shouldDisplayAdd } =
    useContext(TableContext);

  const { malls, hiddenStores, hiddenMalls } = tableSettings;

  const allShownMalls = getShownMalls({
    malls,
    isEdit,
    hiddenMalls,
  });

  const allShownStores = allShownMalls.reduce(
    (cur, mall) => [
      ...cur,
      ...getShownStores({
        stores: mall.stores,
        isEdit,
        hiddenStores,
      }),
    ],
    [],
  );

  // Sort columns to match the same order as in TableMallComponent
  const sortedColumns = sortColumnsByDate(shownColumns);

  const totals = sortedColumns.map((column) => ({
    ...column,
    data: getColumnTotal(
      allShownStores,
      column,
      sortedColumns.find((x) => x.isVisible),
    ),
  }));

  return (
    <div className='TableTotal'>
      <div className='TableTotal__total'>
        Total
      </div>
      <div className='TableTotal__data'>
        {totals.map((x) => (
          <div
            key={x.columnUID}
            className='TableTotal__cell'
            style={{ opacity: !x.isVisible ? 0.6 : 1 }}
          >
            {x?.diffType === 'percentage'
              ? getDiffPercentage(totals[0].data, totals[1].data) === '-'
                ? '0'
                : getDiffPercentage(totals[0].data, totals[1].data) + ' %'
              : addSpaces(x.data, { hideSecond: true })}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TableTotal;
