import React from 'react';
import { Header } from '../../../../../components/Header/Header';
import { Colors } from '../../../../../utils/colors';
import { useTranslation } from 'react-i18next';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import useMallInfo from './useMallInfo';
import ConfirmModal from '../../../../../components/ConfirmModal';
import EditForm from './EditForm';
import { useLocation, useNavigate } from 'react-router';
import ROUTES from '../../../../../routes/route';

function MallInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    mall,
    isEdit,
    setIsEdit,
    isDeleteConfirmVisible,
    setIsDeleteConfirmVisible,
    deleteMall,
    onDeleteConfirm,
    changeMall,
  } = useMallInfo();

  return (
    <>
      <Header
        selected="ADMIN"
        goBack={{
          title: t('malls'),
          onClick: () => navigate(ROUTES.ADMIN.MALLS.LIST),
        }}
      />
      <div
        className='mainContainer'
        style={{ backgroundColor: Colors.mainBlack }}
      >
        <div className='AdminInfo'>
          <div className='AdminInfo__container'>
            {isEdit ? (
              <EditForm
                mall={mall}
                onCancel={() => setIsEdit(false)}
                onSave={changeMall}
              />
            ) : (
              <>
                <div className='AdminInfo__container__controls'>
                  <FormOutlined onClick={() => setIsEdit(true)} />
                  <DeleteOutlined
                    onClick={() => setIsDeleteConfirmVisible(true)}
                  />
                </div>
                <div className='AdminInfo__container__wrapper'>
                  <div className='grey'>{t('adminTable.mallName')}:</div>
                  <div className='white'>{mall?.name || '-'}</div>
                </div>
                <div className='AdminInfo__container__wrapper'>
                  <div className='grey'>{t('adminTable.mallOwners')}:</div>
                  <div className='white'>
                    {mall?.mallOwners
                      ?.map(({ name, surname }) => `${name} ${surname}`)
                      .join(', ') || '-'}
                  </div>
                </div>
                <div className='AdminInfo__container__wrapper'>
                  <div className='grey'>{t('adminTable.mallManagers')}:</div>
                  <div className='white'>
                    {mall?.mallMangers
                      ?.map(({ name, surname }) => `${name} ${surname}`)
                      .join(', ') || '-'}
                  </div>
                </div>
              </>
            )}
            {isDeleteConfirmVisible && (
              <ConfirmModal
                onConfirm={deleteMall}
                onCancel={() => setIsDeleteConfirmVisible(false)}
                text={`${t('deleteMall1')} ${mall?.name} ${t('deleteMall2')}`}
                confirmBtnText={t('delete')}
                confirmDoneText={`${mall?.name} ${t('mallDeleted')}`}
                confirmDoneBtnText={t('continue')}
                onConfirmDone={onDeleteConfirm}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MallInfo;
