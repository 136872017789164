import dayjs from 'dayjs';

/**
 * Sorts columns from oldest to most recent (left to right)
 * @param {Array} columns - Array of column objects with 'from' date property
 * @returns {Array} Sorted array of columns
 */
export const sortColumnsByDate = (columns) => {
  return [...columns].sort((a, b) => {
    if (a.isDiff || b.isDiff) return 0;
    const dateA = dayjs(a.from);
    const dateB = dayjs(b.from);
    return dateA.valueOf() - dateB.valueOf(); // Oldest first
  });
}; 