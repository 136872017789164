import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllStoresGroup } from '../../../../services/Saga/filters/actions';

export function useFilters() {
  const dispatch = useDispatch();
  const allStoresGroup = useSelector(
    (state) => state.filtersReducer.allStoresGroup,
  );

  useEffect(() => {
    dispatch(getAllStoresGroup());
  }, [dispatch]);

  const categories = [
    { id: 'all', name: 'All' },
    ...allStoresGroup,
  ];

  const getFilterLabel = (category) => {
    if (category.id === 'all') return 'All';
    return category.eng || category.name;
  };

  return {
    categories,
    getFilterLabel
  };
}
