import { ActionTypes } from './actionsTypes';

export const login = (payload) => ({
  type: ActionTypes.LOGIN,
  payload,
});
export const logOut = (payload) => ({
  type: ActionTypes.LOG_OUT,
  payload,
});
export const getUserInfo = () => ({
  type: ActionTypes.GET_USER_INFO,
});
export const setUserLanguage = (payload) => ({
  type: ActionTypes.SET_USER_LANGUAGE,
  payload,
});
export const setUserTheme = (payload) => ({
  type: ActionTypes.SET_USER_THEME,
  payload,
});
