import React from 'react';
import { Header } from '../../../../../components/Header/Header';
import { Colors } from '../../../../../utils/colors';
import { useTranslation } from 'react-i18next';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import useUserInfo from './useUserInfo';
import ConfirmModal from '../../../../../components/ConfirmModal';
import { ROLES, roles } from '../../../utils/formatForTables';
import EditForm from './EditForm';
import ROUTES from '../../../../../routes/route';
import { useNavigate } from 'react-router';
import { Tooltip } from 'antd';

function UserInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    user,
    malls,
    isEdit,
    setIsEdit,
    isDeleteConfirmVisible,
    setIsDeleteConfirmVisible,
    deleteUser,
    onDeleteConfirm,
    changeUser,
  } = useUserInfo();

  return (
    <>
      <Header
        selected="ADMIN"
        goBack={{
          title: t('users'),
          onClick: () => navigate(ROUTES.ADMIN.USERS.LIST),
        }}
      />
      <div
        className='mainContainer'
        style={{ backgroundColor: Colors.mainBlack }}
      >
        <div className='AdminInfo'>
          <div className='AdminInfo__container'>
            {isEdit ? (
              <EditForm
                user={user}
                onCancel={() => setIsEdit(false)}
                onSave={changeUser}
                malls={malls}
              />
            ) : (
              <>
                <div className='AdminInfo__container__controls'>
                  <FormOutlined onClick={() => setIsEdit(true)} />
                  <DeleteOutlined
                    onClick={() => setIsDeleteConfirmVisible(true)}
                  />
                </div>
                <div className='AdminInfo__container__wrapper'>
                  <div className='grey'>{t('adminTable.name')}:</div>
                  <div className='white'>{user?.name || '-'}</div>
                </div>
                <div className='AdminInfo__container__wrapper'>
                  <div className='grey'>{t('adminTable.surname')}:</div>
                  <div className='white'>{user?.surname || '-'}</div>
                </div>
                <div className='AdminInfo__container__wrapper'>
                  <div className='grey'>{t('adminTable.phoneNumber')}:</div>
                  <div className='white'>{user?.phoneNumber || '-'}</div>
                </div>
                <div className='AdminInfo__container__wrapper'>
                  <div className='grey'>{t('adminTable.email')}:</div>
                  <div className='white'>{user?.email || '-'}</div>
                </div>
                <div className='AdminInfo__container__wrapper'>
                  <div className='grey'>{t('adminTable.password')}:</div>
                  <div className='white'>
                    <Tooltip title={user?.password || '-'}>
                      {user?.password || '-'}
                    </Tooltip>
                  </div>
                </div>
                <div className='AdminInfo__container__wrapper'>
                  <div className='grey'>{t('adminTable.userRole')}:</div>
                  <div className='white'>
                    {user?.roles?.map((role) => roles[role]).join(', ') || '-'}
                  </div>
                </div>
                <div className='AdminInfo__container__wrapper'>
                  <div className='grey'>{t('adminTable.mall')}:</div>
                  <div className='white'>
                    {user?.roles?.[0] !== ROLES.STORE_MANAGER
                      ? user?.malls?.map((mall) => mall.name)?.join(', ')
                      : malls.find((mall) => mall.id === user?.store?.mallId)
                          ?.name || '-'}
                  </div>
                </div>
                <div className='AdminInfo__container__wrapper'>
                  <div className='grey'>{t('adminTable.store')}:</div>
                  <div className='white'>{user?.store?.name || '-'}</div>
                </div>
              </>
            )}
            {isDeleteConfirmVisible && (
              <ConfirmModal
                onConfirm={deleteUser}
                onCancel={() => setIsDeleteConfirmVisible(false)}
                text={`${t('deleteUser')} ${user?.name}?`}
                confirmBtnText={t('delete')}
                confirmDoneText={`${user?.name} ${t('userDeleted')}`}
                confirmDoneBtnText={t('continue')}
                onConfirmDone={onDeleteConfirm}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UserInfo;
