import * as React from 'react';
const Add = ({ className = 'icon-idle', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    className={className}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeMiterlimit={10}
      d='M17.5 10c0-4.14-3.36-7.5-7.5-7.5-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5Z'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M10 6.875v6.25M13.125 10h-6.25'
    />
  </svg>
);
export default Add;
