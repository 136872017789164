import { ActionTypes } from './actionTypes';

const initialState = {
  accessToken: '',
  refreshToken: '',
  user: {},
  err: false,
  isLoading: true,
  logoutReason: null
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        accessToken: action.payload.accessToken || state.accessToken,
        refreshToken: action.payload.refreshToken || state.refreshToken,
        user: action.payload.user || state.user,
        isLoading: false,
      };
    case ActionTypes.SET_ERROR:
      return {
        ...state,
        err: action.payload,
      };
    case ActionTypes.LOG_OUT:
      return { 
        ...initialState, 
        isLoading: false,
        logoutReason: action.payload 
      };
    case ActionTypes.SET_USER_THEME:
      return { 
        ...state, 
        user: { 
          ...state.user, 
          theme: action.payload 
        } 
      };
    case ActionTypes.SET_IS_LOADING:
      return { 
        ...state, 
        isLoading: action.payload 
      };
    default:
      return state;
  }
};
