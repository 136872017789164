import { shouldClearStorage } from '../utils/version';
import createSagaMiddleware from '@redux-saga/core';
import { applyMiddleware, createStore } from '@reduxjs/toolkit';
import rootReducer from './Redux';
import rootSaga from './Saga';
import { checkTokenState } from '../utils/tokenDebug';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const configureStore = () => {
  if (shouldClearStorage()) {
    localStorage.clear();
  }

  const store = createStore(rootReducer, applyMiddleware(...middleware));
  sagaMiddleware.run(rootSaga);
  
  // Set up token monitoring
  if (process.env.NODE_ENV !== 'production') {
    setInterval(() => {
      const state = store.getState().loginReducer;
      if (state.accessToken || state.refreshToken) {
        checkTokenState();
      }
    }, 30000);
  }

  return store;
};

export const store = configureStore();
export const dispatch = store.dispatch;
