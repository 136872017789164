import React from 'react';
import './styles.scss';
import EyeIcon from '../../../../assets/icons/EyeIcon';

function ToggleableEye({ isDisabled, onClick, style = {} }) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick();
      }}
      className="ToggleableEye"
      style={style}
    >
      <EyeIcon 
        isDisabled={isDisabled}
      />
    </div>
  );
}

export default ToggleableEye;
