import { spawn } from 'redux-saga/effects';
import { watcherCreateMall, watcherCreateStore } from './createNewMallAndStore';
import { watcherCreateNewUser } from './createNewUser';
import {
  watcherGetAllGroups,
  watcherGetAllMalls,
  watcherGetAllMallsUsers,
  watcherGetAllStores,
} from './filters';
import {
  watcherGetGroups,
  watcherGetMalls,
  watcherGetStores,
  watcherGetStoresNewUser,
} from './getMallsList';
import { watcherGetReportingList } from './getReportingList';
import {
  watcherExportTotalIncomeAmount,
  watcherGetTotalIncomeAmount,
} from './getTotalIncomeAmount';
import {
  watcherGetUserInfo,
  watcherLogin,
  watcherLogOut,
  watcherSetUserLanguage,
  watcherSetUserTheme,
} from './loginSaga';
import {
  watcherAddIncome,
  watcherExportIncome,
  watcherGetStoreIncome,
} from './storeIncome';
import {
  watcherGetConsolidatedFood,
  watcherGetConsolidatedIncome,
  watcherGetTopMallIncome,
  watcherGetTopPercentageGrowth,
  watcherGetTopStoreIncome,
  watcherTotalIncome,
} from './totalIncomes';

export default function* rootSaga() {
  try {
    yield spawn(watcherCreateNewUser);
    yield spawn(watcherLogin);
    yield spawn(watcherGetMalls);
    yield spawn(watcherCreateMall);
    yield spawn(watcherLogOut);
    yield spawn(watcherCreateStore);
    yield spawn(watcherGetStores);
    yield spawn(watcherGetGroups);
    yield spawn(watcherGetStoreIncome);
    yield spawn(watcherAddIncome);
    yield spawn(watcherExportIncome);
    yield spawn(watcherTotalIncome);
    yield spawn(watcherGetStoresNewUser);
    yield spawn(watcherGetTopStoreIncome);
    yield spawn(watcherGetTopMallIncome);
    yield spawn(watcherGetReportingList);
    yield spawn(watcherGetAllMalls);
    yield spawn(watcherGetAllStores);
    yield spawn(watcherGetAllGroups);
    yield spawn(watcherGetAllMallsUsers);
    yield spawn(watcherGetTotalIncomeAmount);
    yield spawn(watcherExportTotalIncomeAmount);
    yield spawn(watcherGetUserInfo);
    yield spawn(watcherSetUserLanguage);
    yield spawn(watcherSetUserTheme);
    yield spawn(watcherGetConsolidatedIncome);
    yield spawn(watcherGetConsolidatedFood);
    yield spawn(watcherGetTopPercentageGrowth);
  } catch (error) {
    console.warn('Error in root saga', error);
  }
}
