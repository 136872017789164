import * as React from 'react';
const EyeIcon = ({ className = 'icon icon-idle', isDisabled = false, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    className={className}
    {...props}
  >
    {isDisabled ? (
      <>
        <path
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3 3l14 14M9.9 9.9a2.5 2.5 0 103.2 3.2M7.2 7.2C3.5 8.7 2 12 2 12s2.5 5.5 8 5.5c1.3 0 2.5-.2 3.6-.6m2.7-1.2c1.4-1 2.2-2.1 2.2-3.2 0 0-2.5-5.5-8-5.5-.4 0-.8 0-1.2.1"
        />
      </>
    ) : (
      <>
        <path
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          d="M10 4.5C3.5 4.5 2 10 2 10s1.5 5.5 8 5.5S18 10 18 10s-1.5-5.5-8-5.5z"
        />
        <circle
          cx="10"
          cy="10"
          r="2.5"
          stroke="currentColor"
          strokeWidth="1.2"
          fill="none"
        />
      </>
    )}
  </svg>
);
export default EyeIcon; 