import * as React from 'react';

const HideUp = ({ className = 'icon-idle', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    className={className}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='m15.833 12.5-5.833-5-5.833 5'
    />
  </svg>
);

export default HideUp;
