import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';
import './styles.scss';

const Calendar = ({ dates, onDateChange, onTabChange }) => {
  const { t } = useTranslation();
  const [displayedMonths, setDisplayedMonths] = useState([
    dayjs(dates.from),
    dayjs(dates.from).add(1, 'month')
  ]);
  const [selectedDates, setSelectedDates] = useState({
    from: dayjs(dates.from),
    to: dayjs(dates.to)
  });
  const [selectionInProgress, setSelectionInProgress] = useState(false);
  const [hoverDate, setHoverDate] = useState(null);

  useEffect(() => {
    setSelectedDates({
      from: dayjs(dates.from),
      to: dayjs(dates.to)
    });
  }, [dates]);

  const weekDays = [
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday'),
    t('sunday')
  ];

  const generateMonthDays = (month) => {
    const start = month.startOf('month');
    const end = month.endOf('month');
    const days = [];
    let day = start;

    // Add empty slots for days before the first day of the month
    const firstDayOfWeek = start.day() || 7; // Convert Sunday (0) to 7
    for (let i = 1; i < firstDayOfWeek; i++) {
      days.push(null);
    }

    // Add the actual days of the month
    while (day.isBefore(end) || day.isSame(end, 'day')) {
      days.push(day);
      day = day.add(1, 'day');
    }

    // Add empty slots for days after the last day of the month
    const lastDayOfWeek = end.day() || 7;
    for (let i = lastDayOfWeek + 1; i <= 7; i++) {
      days.push(null);
    }

    return days;
  };

  const handleDayClick = useCallback((day) => {
    if (!day) return; // Ignore clicks on empty slots
    if (!selectionInProgress) {
      setSelectionInProgress(true);
      setSelectedDates({
        from: day,
        to: null
      });
    } else {
      setSelectionInProgress(false);
      const from = selectedDates.from;
      const to = day;

      // Ensure from is before to
      if (from.isAfter(to)) {
        setSelectedDates({
          from: to,
          to: from
        });
        onDateChange({ 
          from: to, 
          to: from 
        });
      } else {
        setSelectedDates({
          from: from,
          to: to
        });
        onDateChange({ 
          from: from, 
          to: to 
        });
      }
      setHoverDate(null);
    }
  }, [selectionInProgress, selectedDates.from, onDateChange]);

  const handleDayHover = useCallback((day) => {
    if (!day) return; // Ignore hover on empty slots
    if (selectionInProgress) {
      setHoverDate(day);
    }
  }, [selectionInProgress]);

  const isDaySelected = useCallback((day) => {
    if (!day) return false; // Handle empty slots
    if (!selectedDates.from) return false;
    if (!selectedDates.to) return day.isSame(selectedDates.from, 'day');
    return (
      day.isSame(selectedDates.from, 'day') ||
      day.isSame(selectedDates.to, 'day') ||
      (day.isAfter(selectedDates.from) && day.isBefore(selectedDates.to))
    );
  }, [selectedDates]);

  const isDayInRange = useCallback((day) => {
    if (!day) return false; // Handle empty slots
    if (selectionInProgress && selectedDates.from && hoverDate) {
      const start = selectedDates.from.isBefore(hoverDate) ? selectedDates.from : hoverDate;
      const end = selectedDates.from.isBefore(hoverDate) ? hoverDate : selectedDates.from;
      return day.isAfter(start) && day.isBefore(end);
    }
    if (!selectedDates.from || !selectedDates.to) return false;
    return day.isAfter(selectedDates.from) && day.isBefore(selectedDates.to);
  }, [selectedDates, selectionInProgress, hoverDate]);

  const isEndpoint = useCallback((day) => {
    if (!day) return false; // Handle empty slots
    if (!selectedDates.from) return false;
    if (selectionInProgress && hoverDate) {
      return day.isSame(selectedDates.from, 'day') || day.isSame(hoverDate, 'day');
    }
    return day.isSame(selectedDates.from, 'day') || day.isSame(selectedDates.to, 'day');
  }, [selectedDates, selectionInProgress, hoverDate]);

  const handleNavigateMonth = useCallback((direction) => {
    setDisplayedMonths(months => months.map(month => month.add(direction === 'next' ? 1 : -1, 'month')));
  }, []);

  const formatMonthYear = (date) => {
    const monthKey = date.format('MMMM').toLowerCase();
    return `${t(monthKey)} ${date.format('YYYY')}`;
  };

  const renderMonth = (month) => {
    const days = generateMonthDays(month);
    const weeks = [];
    let currentWeek = [];

    days.forEach((day) => {
      currentWeek.push(day);
      if (currentWeek.length === 7) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    });

    return (
      <div className="calendar__month">
        <div className="calendar__month-header">
          {formatMonthYear(month)}
        </div>
        <div className="calendar__weekdays">
          {weekDays.map(day => (
            <div key={day} className="calendar__weekday">{day}</div>
          ))}
        </div>
        <div className="calendar__weeks">
          {weeks.map((week, weekIndex) => (
            <div key={weekIndex} className="calendar__week">
              {week.map((day, dayIndex) => (
                <button
                  key={day ? day.format('YYYY-MM-DD') : `empty-${dayIndex}`}
                  type="button"
                  className={classNames('calendar__day', {
                    '--selected': day && isDaySelected(day),
                    '--in-range': day && isDayInRange(day),
                    '--endpoint': day && isEndpoint(day),
                    '--empty': !day,
                    '--hover': day && selectionInProgress && hoverDate?.isSame(day, 'day')
                  })}
                  onClick={() => handleDayClick(day)}
                  onMouseEnter={() => handleDayHover(day)}
                  disabled={!day}
                >
                  {day ? day.format('D') : ''}
                </button>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="calendar">
      <div className="calendar__months">
        {displayedMonths.map((month, index) => renderMonth(month))}
      </div>
      <div className="calendar__footer">
        <div className="calendar__navigation">
          <button 
            type="button" 
            className="calendar__nav-button"
            onClick={() => handleNavigateMonth('prev')}
          >
            ←
          </button>
          <button 
            type="button" 
            className="calendar__nav-button"
            onClick={() => handleNavigateMonth('next')}
          >
            →
          </button>
        </div>
        <div className="calendar__tabs">
          <button
            type="button"
            className="calendar__tab-button"
            onClick={() => onTabChange('presets')}
          >
            {t('editor_tab_presets')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Calendar; 