import packageJson from '../../package.json';

const getVersion = () => {
  const envVersion = process.env.REACT_APP_VERSION;
  const pkgVersion = packageJson.version;
  
  if (process.env.NODE_ENV === 'development') {
    console.log('Environment Version:', envVersion);
    console.log('Package Version:', pkgVersion);
  }
  
  return envVersion || pkgVersion; // Use REACT_APP_VERSION if available
};

export const APP_VERSION = getVersion();

export const getStoredVersion = () => {
  const stored = localStorage.getItem('app_version');
  if (process.env.NODE_ENV === 'development') {
    console.log('Stored Version:', stored);
  }
  return stored;
};


export const setStoredVersion = () => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Setting Version:', APP_VERSION);
  }
  localStorage.setItem('app_version', APP_VERSION);
};

export const shouldClearStorage = () => {
  const storedVersion = getStoredVersion();
  console.log('Version Check:', {
    stored: storedVersion,
    current: APP_VERSION,
    environment: process.env.NODE_ENV,
    timestamp: new Date().toISOString()
  });
  
  if (!storedVersion) {
    setStoredVersion();
    return false; // Don't clear on first run
  }
  
  // Only clear if versions don't match and we're not using "latest"
  if (storedVersion !== APP_VERSION && storedVersion !== "latest") {
    setStoredVersion();
    return true;
  }
  
  return false;
};

export const isNewVersion = () => {
  const storedVersion = getStoredVersion();
  if (!storedVersion) {
    setStoredVersion();
    return true;
  }
  return storedVersion !== APP_VERSION;
};

export const parseVersion = (version) => {
  const [major, minor, patch] = version.split('.').map(Number);
  return { major, minor, patch };
};

export const compareVersions = (v1, v2) => {
  const ver1 = parseVersion(v1);
  const ver2 = parseVersion(v2);

  if (ver1.major !== ver2.major) return ver1.major - ver2.major;
  if (ver1.minor !== ver2.minor) return ver1.minor - ver2.minor;
  return ver1.patch - ver2.patch;
}; 