import * as React from 'react';
const Delete = ({ className = 'icon-idle', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    className={className}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M16.875 5h-13.75M15.625 5v11.25a.625.625 0 0 1-.625.625H5a.625.625 0 0 1-.625-.625V5m1.875 0V3.75A.625.625 0 0 1 6.875 3.125h6.25a.625.625 0 0 1 .625.625V5M8.125 8.75v5M11.875 8.75v5'
    />
  </svg>
);
export default Delete;
