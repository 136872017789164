import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import './styles.scss';

const PresetSection = ({ title, presets, onSelect }) => (
  <div className="preset-section">
    <div className="preset-section__title">{title}</div>
    <div className="preset-section__items">
      {presets.map((preset) => (
        <button
          key={preset.label}
          className="preset-button"
          onClick={() => onSelect(preset)}
        >
          <div className="preset-button__content">
            <span className="preset-button__icon">{preset.icon}</span>
            <div className="preset-button__text">
              <span className="preset-button__label">{preset.label}</span>
              <span className="preset-button__range">{preset.range}</span>
            </div>
          </div>
        </button>
      ))}
    </div>
  </div>
);

const Presets = ({ onSelect }) => {
  const { t } = useTranslation();
  const now = dayjs();

  const sections = [
    {
      title: t('date'),
      presets: [
        {
          label: t('today'),
          range: t('current_day'),
          from: now.startOf('day'),
          to: now.endOf('day'),
          icon: '📅'
        },
        {
          label: t('yesterday'),
          range: t('previous_day'),
          from: now.subtract(1, 'day').startOf('day'),
          to: now.subtract(1, 'day').endOf('day'),
          icon: '⏪'
        }
      ]
    },
    {
      title: t('month'),
      presets: [
        {
          label: t('current_month'),
          range: t('current_month_range'),
          from: now.startOf('month'),
          to: now.subtract(1, 'day').endOf('day'),
          icon: '📆'
        },
        {
          label: t('previous_month'),
          range: t('previous_month_range'),
          from: now.subtract(1, 'month').startOf('month'),
          to: now.subtract(1, 'month').endOf('month'),
          icon: '⬅️'
        },
        {
          label: t('last_3_months'),
          range: t('last_3_months_range'),
          from: now.subtract(3, 'month').startOf('month'),
          to: now.subtract(1, 'day').endOf('day'),
          icon: '🔄'
        },
        {
          label: t('last_6_months'),
          range: t('last_6_months_range'),
          from: now.subtract(6, 'month').startOf('month'),
          to: now.subtract(1, 'day').endOf('day'),
          icon: '↩️'
        }
      ]
    },
    {
      title: t('week'),
      presets: [
        {
          label: t('last_7_days'),
          range: t('last_7_days_range'),
          from: now.subtract(7, 'day').startOf('day'),
          to: now.subtract(1, 'day').endOf('day'),
          icon: '📊'
        },
        {
          label: t('last_14_days'),
          range: t('last_14_days_range'),
          from: now.subtract(14, 'day').startOf('day'),
          to: now.subtract(1, 'day').endOf('day'),
          icon: '📈'
        }
      ]
    },
    {
      title: t('year'),
      presets: [
        {
          label: t('this_Year'),
          range: t('current_year_range'),
          from: now.startOf('year'),
          to: now.subtract(1, 'day').endOf('day'),
          icon: '🗓️'
        },
        {
          label: t('last_year'),
          range: t('previous_year'),
          from: now.subtract(1, 'year').startOf('year'),
          to: now.subtract(1, 'year').endOf('year'),
          icon: '⏮️'
        },
        {
          label: t('last_2_years'),
          range: t('last_2_years_range'),
          from: now.subtract(2, 'year').startOf('year'),
          to: now.subtract(1, 'day').endOf('day'),
          icon: '📚'
        }
      ]
    },
    {
      title: t('quarter'),
      presets: [
        {
          label: t('quarter_1'),
          range: t('january_to_march'),
          from: now.month(0).startOf('month'),
          to: now.month(2).endOf('month'),
          icon: '1️⃣'
        },
        {
          label: t('quarter_2'),
          range: t('april_to_june'),
          from: now.month(3).startOf('month'),
          to: now.month(5).endOf('month'),
          icon: '2️⃣'
        },
        {
          label: t('quarter_3'),
          range: t('july_to_september'),
          from: now.month(6).startOf('month'),
          to: now.month(8).endOf('month'),
          icon: '3️⃣'
        },
        {
          label: t('quarter_4'),
          range: t('october_to_december'),
          from: now.month(9).startOf('month'),
          to: now.month(11).endOf('month'),
          icon: '4️⃣'
        }
      ]
    }
  ];

  // Group sections into columns
  const columns = [
    sections.slice(0, 2), // Daily and Monthly
    sections.slice(2, 4), // Weekly and Yearly
    sections.slice(4) // Quarterly
  ];

  return (
    <div className="presets">
      <div className="presets__grid">
        {columns.map((columnSections, i) => (
          <div key={i} className="presets__column">
            {columnSections.map(section => (
              <PresetSection
                key={section.title}
                title={section.title}
                presets={section.presets}
                onSelect={onSelect}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Presets; 