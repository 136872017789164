import React, { useContext, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { IncomeTableContext } from '../../IncomeTableContext';
import { TableContext } from '../Table/TableContext';
import EditorHeader from './components/EditorHeader';
import ColumnsOverview from './components/ColumnsOverview';
import EditingArea from './components/EditingArea';
import { standartColumnProperties } from '../../const';
import generateUID from '../../utils/generateUID';
import './styles.scss';

const TableColumnEditor = ({ onClose }) => {
  const { t } = useTranslation();
  const { tableUID, tableSettings, shownColumns } = useContext(TableContext);
  const { 
    selectedColumn,
    setSelectedColumn,
    changeColumnType,
    toggleColumnTax,
    removeColumn,
    toggleColumnVisibility,
    addColumn,
    deleteTable,
    changeColumnDates,
    activeEditorTab,
    setActiveEditorTab,
    selectTable
  } = useContext(IncomeTableContext);

  // Set initial selection when opening the editor
  useEffect(() => {
    selectTable(tableUID);
    if (!selectedColumn) {
      const firstColumn = shownColumns.find(col => !col.isDiff);
      if (firstColumn) {
        setSelectedColumn(firstColumn.columnUID);
      }
    }

    return () => {
      setSelectedColumn(null);
      selectTable(null);
    }
  }, [tableUID]); // Only run on mount and when tableUID changes

  useEffect(() => {
    // Auto-delete table if it has no columns
    const nonDiffColumns = shownColumns.filter(col => !col.isDiff);
    if (nonDiffColumns.length === 0) {
      deleteTable(tableUID);
      onClose();
    }
  }, [shownColumns, deleteTable, tableUID, onClose]);

  const handleClose = useCallback((e) => {
    if (!e || e.target.classList.contains('column-editor__overlay')) {
      setSelectedColumn(null);
      selectTable(null);
      onClose();
    }
  }, [onClose, setSelectedColumn, selectTable]);

  const handleDeleteTable = useCallback(async () => {
    if (window.confirm(t('delete_table_with_exit_hint'))) {
      const success = await deleteTable(tableUID);
      if (success) {
        handleClose();
      }
    }
  }, [tableUID, deleteTable, handleClose, t]);

  const handleAddColumn = async () => {
    const now = dayjs();
    await addColumn(tableUID, {
        from: now.startOf('month'),
        to: now.endOf('month')
    });
  };

  const handleRemoveColumn = async (column) => {
    const nonDiffColumns = shownColumns.filter(col => !col.isDiff);
    if (nonDiffColumns.length === 1) {
      if (window.confirm(t('delete_last_column_with_exit_hint'))) {
        await removeColumn(tableUID, column.columnUID);
      }
    } else {
      await removeColumn(tableUID, column.columnUID);
      
      // If we're deleting the currently selected column
      if (selectedColumn === column.columnUID) {
        // Find the first non-diff column that isn't the one being deleted
        const remainingColumns = shownColumns.filter(col => !col.isDiff && col.columnUID !== column.columnUID);
        const firstColumn = remainingColumns[0];
        
        if (firstColumn) {
          // Wait for state to update
          setTimeout(() => {
            setSelectedColumn(firstColumn.columnUID);
            setActiveEditorTab('picker');
          }, 0);
        }
      }
    }
  };

  const handleToggleVisibility = (column) => {
    console.log('Toggling visibility for column:', column);
    toggleColumnVisibility(tableUID, column.columnUID);
  };

  const selectedColumnData = shownColumns.find(col => col.columnUID === selectedColumn);

  const shouldShowAdd = shownColumns.filter(x => !x.isDiff).length < 6;

  const handleTaxChange = (option) => {
    toggleColumnTax(tableUID, selectedColumn, option.key);
  };

  const handleAlignVat = (taxSetting) => {
    // Get regular columns (non-diff) from shownColumns
    const regularColumns = shownColumns.filter(col => !col.isDiff);
    
    // Update both columns to the same VAT setting
    regularColumns.forEach(col => {
      toggleColumnTax(tableUID, col.columnUID, taxSetting);
    });
  };

  return createPortal(
    <div 
      className="column-editor__overlay"
      onClick={handleClose}
    >
      <div 
        className="column-editor__wrapper"
        onClick={e => e.stopPropagation()}
      >
        <EditorHeader 
          activeTab={activeEditorTab}
          onTabChange={setActiveEditorTab}
          onDeleteTable={handleDeleteTable}
        />

        <div className="column-editor__content">
          <ColumnsOverview 
            columns={tableSettings.columns}
            selectedColumnId={selectedColumn}
            onDateClick={(column) => {
              setSelectedColumn(column.columnUID);
              setActiveEditorTab('picker');
            }}
            onTypeClick={(column) => {
              setSelectedColumn(column.columnUID);
              setActiveEditorTab('types');
            }}
            onTaxClick={(column) => {
              setSelectedColumn(column.columnUID);
              setActiveEditorTab('tax');
            }}
            onRemoveColumn={handleRemoveColumn}
            onToggleVisibility={handleToggleVisibility}
            onAddColumn={handleAddColumn}
            shouldShowAdd={shouldShowAdd}
            activeTab={activeEditorTab}
            onAlignVat={handleAlignVat}
          />

          {selectedColumnData && (
            <EditingArea 
              activeTab={activeEditorTab}
              selectedColumn={selectedColumnData}
              onDateChange={(dates) => changeColumnDates(tableUID, selectedColumn, dates)}
              onTypeChange={(type) => changeColumnType(tableUID, selectedColumn, type)}
              onTaxChange={handleTaxChange}
              onTabChange={setActiveEditorTab}
            />
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default TableColumnEditor; 