import React from 'react';
import { IncomeTableProvider } from './IncomeTableContext';
import Tables from './components/Tables';
import DashboardSideMenu from './components/DashboardSideMenu';

function IncomeTable() {
  return (
    <IncomeTableProvider>
      <>
        <Tables />
        <DashboardSideMenu />
      </>
    </IncomeTableProvider>
  );
}

export default IncomeTable;
