import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MinusCircle from '../../../../../../assets/icons/MinusCircle';
import ToggleableEye from '../../../ToggleableEye';
import Add from '../../../../../../assets/icons/Add';
import { formatDateRange } from '../../../../utils/formatDateRange';
import { getShouldDisplayDiff } from '../../../../utils/getDiff';
import dayjs from 'dayjs';
import { sortColumnsByDate } from '../../../../utils/columnUtils';
import './styles.scss';

const VatMismatchHelper = ({ columns, onAlignVat }) => {
  const { t } = useTranslation();
  const [isDismissed, setIsDismissed] = useState(false);
  const [loadingState, setLoadingState] = useState(null);
  const regularColumns = columns.filter(col => !col.isDiff);

  // Only show if we have exactly 2 regular columns with different VAT settings and not dismissed
  if (isDismissed || regularColumns.length !== 2 || regularColumns[0].tax === regularColumns[1].tax) {
    return null;
  }

  const handleAlignVat = async (setting) => {
    setLoadingState(setting);
    await onAlignVat(setting);
    setLoadingState(null);
  };

  return (
    <div className="vat-mismatch-helper">
      <div className="vat-mismatch-helper__icon">ℹ️</div>
      <span className="vat-mismatch-helper__text">{t('diff_columns_vat_mismatch')}</span>
      <div className="vat-mismatch-helper__buttons">
        <button
          type="button"
          className={`vat-mismatch-helper__button ${loadingState === 'excluded' ? '--loading' : ''} ${regularColumns.some(col => col.tax === 'excluded') ? '--selected' : ''}`}
          onClick={() => handleAlignVat('excluded')}
          disabled={loadingState !== null}
        >
          {t('align_vat_without')}
        </button>
        <button
          type="button"
          className={`vat-mismatch-helper__button ${loadingState === 'included' ? '--loading' : ''} ${regularColumns.some(col => col.tax === 'included') ? '--selected' : ''}`}
          onClick={() => handleAlignVat('included')}
          disabled={loadingState !== null}
        >
          {t('align_vat_with')}
        </button>
      </div>
      <button
        type="button"
        className="vat-mismatch-helper__close"
        onClick={() => setIsDismissed(true)}
        title={t('close_helper')}
      >
        ×
      </button>
    </div>
  );
};

const ColumnDisplay = ({
  column,
  onDateClick,
  onTypeClick,
  onTaxClick,
  isSelected,
  activeTab
}) => {
  const { t } = useTranslation();

  if (column.isDiff) {
    return (
      <div className="column-display" data-type="diff">
        <div className="column-display__diff">
          <span>{column.diffType === 'numbers' ? t('Diff') : t('Diff %')}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={`column-display ${isSelected ? '--selected' : ''}`}>
      <button 
        className={`column-display__button ${isSelected && activeTab === 'picker' ? '--active' : ''}`}
        onClick={() => onDateClick(column)}
      >
        {formatDateRange(column.from, column.to, t)}
      </button>
      <button 
        className={`column-display__button ${isSelected && activeTab === 'types' ? '--active' : ''}`}
        onClick={() => onTypeClick(column)}
      >
        {column.type ? t(column.type) : t('income')}
      </button>
      {column.type !== 'averageTransaction' && (
        <button 
          className={`column-display__button ${isSelected && activeTab === 'tax' ? '--active' : ''}`}
          onClick={() => onTaxClick(column)}
        >
          {column.tax === 'included' ? t('vat_display_included') : t('vat_display_excluded')}
        </button>
      )}
    </div>
  );
};

const ColumnsOverview = ({
  columns,
  selectedColumnId,
  onDateClick,
  onTypeClick,
  onTaxClick,
  onRemoveColumn,
  onToggleVisibility,
  onAddColumn,
  shouldShowAdd,
  activeTab,
  onAlignVat
}) => {
  const { t } = useTranslation();

  const handleToggleVisibility = (column) => {
    onToggleVisibility(column);
  };

  // Filter columns to show based on conditions
  const shouldShowDiff = getShouldDisplayDiff(columns);
  const visibleColumns = columns.filter(col => {
    if (!col.isDiff) return true;
    return shouldShowDiff && (col.isVisible || !col.isVisible); // Show diff columns when they should be displayed
  });

  // Sort columns to match the same order as in TableMallComponent and TableTotal
  const sortedColumns = sortColumnsByDate(visibleColumns);

  return (
    <div className="columns-overview">
      <VatMismatchHelper columns={columns} onAlignVat={onAlignVat} />
      <div className="columns-overview__row">
        {sortedColumns.map(column => (
          <div 
            key={column.columnUID}
            className={`columns-overview__item ${!column.isVisible ? '--hidden' : ''}`}
          >
            <div className="columns-overview__item-action">
              {column.isDiff ? (
                <div 
                  className="toggle-button-wrapper" 
                  title={column.isVisible ? t('Hide diff column') : t('Show diff column')}
                >
                  <ToggleableEye 
                    isDisabled={!column.isVisible}
                    onClick={() => handleToggleVisibility(column)}
                  />
                </div>
              ) : (
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => onRemoveColumn(column)}
                  title={t('Delete column')}
                >
                  <MinusCircle />
                </button>
              )}
            </div>
            <ColumnDisplay
              column={column}
              onDateClick={onDateClick}
              onTypeClick={onTypeClick}
              onTaxClick={onTaxClick}
              isSelected={selectedColumnId === column.columnUID}
              activeTab={activeTab}
            />
          </div>
        ))}
        {shouldShowAdd && (
          <div className="columns-overview__item">
            <div className="columns-overview__item-action" />
            <button
              type="button"
              className="column-display add-column-display"
              onClick={onAddColumn}
              data-tooltip={t('Add new column')}
            >
              <Add />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ColumnsOverview; 