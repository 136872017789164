import { combineReducers } from 'redux';
import { loginReducer } from './loginReducer';
import { mallsReducer } from './mallsReducer';
import { storeIncomeReducer } from './storeIncomeReducer';
import { leftMenuReducer } from './leftMenuReducer';
import { totalIncomeCardReducer } from './totalIncomeCardsReducer';
import { reportingListReducer } from './reportingListReducer';
import { filtersReducer } from './filtersReducer';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';
import createFilter from 'redux-persist-transform-filter';
import { APP_VERSION } from '../../utils/version';
import { ENV_TYPE } from '../../utils/constants';
import { headerReducer } from './headerReducer';

const saveToken = createFilter('loginReducer', ['accessToken', 'refreshToken', 'user']);

const reducers = combineReducers({
  loginReducer,
  mallsReducer,
  storeIncomeReducer,
  leftMenuReducer,
  totalIncomeCardReducer,
  reportingListReducer,
  filtersReducer,
  headerReducer,
});

const persistConfig = {
  key: `omsetningen_${ENV_TYPE}`,
  version: APP_VERSION,
  storage: localStorage,
  whitelist: ['loginReducer', 'reportingListReducer', 'headerReducer'],
  transforms: [saveToken],
  migrate: (state) => {
    if (!state) {
      return Promise.resolve({});
    }

    if (!state._persist) {
      return Promise.resolve({
        ...state,
        _persist: {
          version: APP_VERSION
        }
      });
    }

    if (state._persist.version !== APP_VERSION) {
      setStoredVersion();
      
      const { accessToken, refreshToken, user } = state.loginReducer || {};
      return Promise.resolve({
        loginReducer: { accessToken, refreshToken, user },
        reportingListReducer: undefined,
        _persist: {
          ...state._persist,
          version: APP_VERSION
        }
      });
    }

    return Promise.resolve(state);
  }
};

const rootReducer = persistReducer(persistConfig, reducers);
export default rootReducer;


