import React from 'react';
import Table from '../Table';
import './styles.scss';
import _ from 'lodash';
import useTables from './useTables';

function Tables() {
  const {
    animation,
    getClassName,
    getTopOffset,
    TABLE_MARGIN,
    tablesWithDelay,
    selectedTable,
  } = useTables();

  return (
    <div className="Tables">
      {tablesWithDelay.map((tableUID, index) => (
        <div
          style={{
            marginTop: TABLE_MARGIN,
            top: getTopOffset(tablesWithDelay, tableUID),
            zIndex: 1 + index,
          }}
          id={tableUID}
          className={
            animation ? getClassName(tableUID, selectedTable) : undefined
          }
          key={tableUID}
        >
          <Table tableUID={tableUID} />
        </div>
      ))}
    </div>
  );
}

export default Tables;
