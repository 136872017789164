import React, { useContext } from 'react';
import TableMallComponent from '../TableMallComponent';
import TableHeader from '../TableHeader';
import TableTotal from '../TableTotal';
import { TableContext, TableProvider } from './TableContext';
import { IncomeTableContext } from '../../IncomeTableContext';
import TableColumnEditor from '../TableColumnEditor';

function Table({ tableUID }) {
  const { tablesSettings, isEditorOpen, setIsEditorOpen } = useContext(IncomeTableContext);

  const calculateTableWidth = (table) => {
    if (!table) return 0;
    
    const COLUMN_WIDTH = 150;
    const BASE_WIDTH = 160;
    const ADD_COLUMN_WIDTH = 80;
    const DIFF_COLUMN_WIDTH = 100;

    const shouldDisplayAdd = table.columns.filter(x => !x.isDiff).length < 6;
    const diffColumns = table.columns.filter(x => x.isDiff && x.isVisible);

    return (
      BASE_WIDTH +
      (shouldDisplayAdd ? ADD_COLUMN_WIDTH * 2 : 0) + // *2 for start and end dates
      (diffColumns.length * DIFF_COLUMN_WIDTH) +
      (table.columns.filter(x => !x.isDiff).length * COLUMN_WIDTH)
    );
  };

  if (tablesSettings[tableUID]) {
    const tableWidth = calculateTableWidth(tablesSettings[tableUID]);
    
    return (
      <TableProvider tableUID={tableUID}>
        <>
          <div style={{ width: `${tableWidth}px` }}>
            <TableHeader />
            <TableContext.Consumer>
              {({ malls }) => (
                <>
                  {malls.map((mall) => (
                    <TableMallComponent mall={mall} key={mall.id} />
                  ))}
                </>
              )}
            </TableContext.Consumer>
            <TableTotal />
          </div>
          {isEditorOpen === tableUID && (
            <TableColumnEditor onClose={() => setIsEditorOpen(null)} />
          )}
        </>
      </TableProvider>
    );
  }

  return null;
}

export default Table;
