import dayjs from 'dayjs';

export const formatDateRange = (from, to, t) => {
  if (!from || !to) return '';
  const fromDate = dayjs(from);
  const toDate = dayjs(to);
  
  const isFullMonth = (start, end) => {
    return start.date() === 1 && end.date() === start.endOf('month').date();
  };

  const isFullYear = (start, end) => {
    return start.month() === 0 && start.date() === 1 && 
           end.month() === 11 && end.date() === 31;
  };

  const isQuarter = (start, end) => {
    return start.date() === 1 && 
           end.date() === end.endOf('month').date() && 
           end.diff(start, 'month') === 2;
  };

  const getMonthName = (date, short = false) => {
    const monthKey = short ? 
      date.format('MMM').toLowerCase() : 
      date.format('MMMM').toLowerCase();
    return t(monthKey);
  };

  const formatDate = (date, includeYear = true, includeMonth = true) => {
    const day = date.format('D');
    const month = includeMonth ? ` ${getMonthName(date, true)}` : '';
    return `${day}${month}${includeYear ? ` ${date.format('YYYY')}` : ''}`;
  };

  const formatMonth = (date, includeYear = true) => {
    const month = getMonthName(date);
    return `${month}${includeYear ? ` ${date.format('YYYY')}` : ''}`;
  };

  // Handle single day selection
  if (fromDate.isSame(toDate, 'day')) {
    return formatDate(fromDate, true);
  }

  // Handle full year
  if (isFullYear(fromDate, toDate) && fromDate.isSame(toDate, 'year')) {
    return fromDate.format('YYYY');
  }

  // Handle quarters or multi-month ranges
  if (isQuarter(fromDate, toDate) || 
      (fromDate.date() === 1 && toDate.date() === toDate.endOf('month').date())) {
    const startMonth = getMonthName(fromDate, true);
    const endMonth = getMonthName(toDate, true);
    const startYear = fromDate.format('YYYY');
    const endYear = toDate.format('YYYY');
    
    if (startYear === endYear) {
      if (startMonth === endMonth) {
        return formatMonth(fromDate, true);
      }
      return `${startMonth} - ${endMonth} ${startYear}`;
    }
    return `${startMonth} ${startYear} - ${endMonth} ${endYear}`;
  }

  if (fromDate.isSame(toDate, 'year')) {
    if (fromDate.isSame(toDate, 'month')) {
      if (isFullMonth(fromDate, toDate)) {
        return formatMonth(fromDate, true);
      }
      return `${formatDate(fromDate, false, false)} - ${formatDate(toDate, false, false)} ${formatMonth(fromDate, true)}`;
    }
    return `${formatDate(fromDate, false)} - ${formatDate(toDate, false)} ${fromDate.format('YYYY')}`;
  }

  // Different years, always include full dates
  return `${formatDate(fromDate)} - ${formatDate(toDate)}`;
}; 