import * as React from 'react';

const Pen = ({ className = 'icon-idle', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    className={className}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='m14.375 2.5-9.688 9.688-1.562 4.687 4.688-1.563 9.687-9.687L14.375 2.5Z'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='m11.875 5 3.125 3.125'
    />
  </svg>
);
export default Pen;
