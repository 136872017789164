import React from 'react';
import ArrowNext from '../../../assets/icons/ArrowNext';

const HeaderBackButton = ({ title, onClick }) => (
  <div className="go-back" onClick={onClick}>
    <ArrowNext className="back-arrow" />
    <span>{title}</span>
  </div>
);

export default HeaderBackButton; 