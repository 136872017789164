import { jwtDecode } from 'jwt-decode';
import { store } from '../services';

export const logTokenInfo = () => {
  const state = store.getState().loginReducer;
  const { accessToken, refreshToken } = state;

  console.group('Token Debug Info');
  console.log('Current Time:', new Date().toISOString());
  
  if (accessToken) {
    try {
      const decodedAccess = jwtDecode(accessToken);
      console.log('Access Token:', {
        token: `${accessToken.substring(0, 20)}...${accessToken.substring(accessToken.length - 20)}`,
        expiresAt: new Date(decodedAccess.exp * 1000).toISOString(),
        timeUntilExpiry: Math.floor((decodedAccess.exp * 1000 - Date.now()) / 1000) + ' seconds',
        decoded: decodedAccess
      });
    } catch (e) {
      console.log('Access Token: Invalid or expired');
    }
  } else {
    console.log('Access Token: Not present');
  }

  if (refreshToken) {
    try {
      const decodedRefresh = jwtDecode(refreshToken);
      console.log('Refresh Token:', {
        token: `${refreshToken.substring(0, 20)}...${refreshToken.substring(refreshToken.length - 20)}`,
        expiresAt: new Date(decodedRefresh.exp * 1000).toISOString(),
        timeUntilExpiry: Math.floor((decodedRefresh.exp * 1000 - Date.now()) / 1000) + ' seconds',
        decoded: {
          user: decodedRefresh.user,
          jti: decodedRefresh.jti,
          ppid: decodedRefresh.ppid,
          iat: new Date(decodedRefresh.iat * 1000).toISOString(),
          exp: new Date(decodedRefresh.exp * 1000).toISOString()
        }
      });
    } catch (e) {
      console.log('Refresh Token: Invalid or expired', e);
    }
  } else {
    console.log('Refresh Token: Not present');
  }

  console.groupEnd();
};

export const checkTokenState = () => {
  const state = store.getState().loginReducer;
  const { accessToken, refreshToken } = state;
  
  console.group('Token State Check');
  console.log('Current Time:', new Date().toISOString());
  
  if (accessToken) {
    try {
      const decoded = jwtDecode(accessToken);
      console.log('Access Token Valid:', new Date(decoded.exp * 1000) > new Date());
      console.log('Time until expiry:', Math.floor((decoded.exp * 1000 - Date.now()) / 1000), 'seconds');
    } catch (e) {
      console.log('Access Token Invalid');
    }
  }
  
  if (refreshToken) {
    try {
      const decoded = jwtDecode(refreshToken);
      console.log('Refresh Token Valid:', new Date(decoded.exp * 1000) > new Date());
      console.log('Time until expiry:', Math.floor((decoded.exp * 1000 - Date.now()) / 1000), 'seconds');
    } catch (e) {
      console.log('Refresh Token Invalid');
    }
  }
  
  console.groupEnd();
};

// Make it available globally in development
if (process.env.NODE_ENV === 'development') {
  window.logTokenInfo = logTokenInfo;
} 