import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './styles.scss';

const TaxSelector = ({ selectedTax, onSelect }) => {
  const { t } = useTranslation();

  const options = [
    {
      key: 'included',
      label: t('included'),
      description: t('vat_included_description'),
      icon: '💵'
    },
    {
      key: 'excluded',
      label: t('excluded'),
      description: t('vat_excluded_description'),
      icon: '🧾'
    }
  ];

  return (
    <div className="tax-selector">
      <div className="tax-selector__grid">
        {options.map(option => (
          <button
            key={option.key}
            type="button"
            className={classNames('tax-selector__item', {
              '--active': selectedTax === option.key
            })}
            onClick={() => onSelect(option)}
          >
            <div className="tax-selector__item-content">
              <span className="tax-selector__item-icon">{option.icon}</span>
              <div className="tax-selector__item-text">
                <span className="tax-selector__item-label">{option.label}</span>
                <span className="tax-selector__item-description">{option.description}</span>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default TaxSelector; 