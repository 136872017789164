import React, { useMemo } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Colors } from '../utils/colors';
import { getRefreshTokenExpiryFormatted } from '../utils/tokenUtils';

export const SessionExpiredAlert = ({ open = false, onClose, error }) => {
  const { t } = useTranslation();
  
  const { value, unit } = useMemo(() => getRefreshTokenExpiryFormatted(), []);
  
  const getMessage = () => {
    const { code } = error?.response?.data || {};
    switch (code) {
      case 401000:
        return t('invalid_token');
      case 401001:
        return t('email_password');
      case 401005:
        return (
          <Stack spacing={3}>
            <Box sx={{ textAlign: 'center', py: 1 }}>
              <Typography 
                variant="h5" 
                sx={{ 
                  color: Colors.mainYellow,
                  fontWeight: 'bold',
                  mb: 3
                }}
              >
                {t('session_expired_title')}
              </Typography>
              <Typography 
                sx={{ 
                  color: Colors.white,
                  fontSize: '1.1rem',
                  mb: 2
                }}
              >
                {t('session_expired')}
              </Typography>
              <Typography 
                sx={{ 
                  color: Colors.white,
                  opacity: 0.9,
                  fontSize: '0.95rem',
                  mb: 2
                }}
              >
                {t(`session_expired_detail_${unit}`, { count: value })}
              </Typography>
              <Typography 
                sx={{ 
                  color: Colors.mainYellow,
                  fontSize: '1.1rem',
                  fontWeight: '500'
                }}
              >
                {t('session_expired_action')}
              </Typography>
            </Box>
          </Stack>
        );
      default:
        return t('session_expired');
    }
  };

  return (
    <Dialog 
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: Colors.mainBlack,
          border: `2px solid ${Colors.mainYellow}`,
          borderRadius: '12px',
          minWidth: '400px',
          maxWidth: '450px',
          '& .MuiDialogContent-root': {
            padding: '24px 32px'
          },
          '& .MuiDialogActions-root': {
            padding: '16px 32px 24px',
            justifyContent: 'center'
          }
        }
      }}
    >
      <DialogContent>
        {getMessage()}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          variant="contained"
          sx={{ 
            backgroundColor: Colors.mainYellow,
            color: Colors.mainBlack,
            fontSize: '1rem',
            fontWeight: '500',
            padding: '8px 32px',
            borderRadius: '8px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: Colors.mainYellow,
              opacity: 0.9,
              transform: 'scale(1.02)',
              transition: 'all 0.2s'
            }
          }}
        >
          {t('lets_go')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SessionExpiredAlert.defaultProps = {
  open: false,
  onClose: () => {},
  error: null
}; 