import axios from 'axios';
import { MAIN_URL } from '../../utils/constants';

// Create base instance
const axiosInstance = axios.create({
  baseURL: MAIN_URL + '/v1/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Create separate instance for token refresh
export const refreshInstance = axios.create({
  baseURL: MAIN_URL + '/v1/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Import and setup interceptors after instances are created
const setupInterceptorsTo = require('./setupInterceptors').default;
setupInterceptorsTo(axiosInstance, refreshInstance);

export default axiosInstance;
