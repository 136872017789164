import { ActionTypes } from './actionTypes';

export const setUser = (payload) => ({
  type: ActionTypes.SET_USER,
  payload,
});
export const setError = (payload) => ({
  type: ActionTypes.SET_ERROR,
  payload,
});
export const logOutRed = (reason) => ({
  type: ActionTypes.LOG_OUT,
  payload: reason
});
export const setIsLoading = (payload) => ({
  type: ActionTypes.SET_IS_LOADING,
  payload,
});
export const setTheme = (theme) => ({
  type: ActionTypes.SET_USER_THEME,
  payload: theme
});
