export const Colors = {
  mainYellow: 'rgba(218, 253, 0, 1)',
  mainYellow055: 'rgba(218, 253, 0, 0.55)',
  mainBlack: 'rgba(18, 18, 18, 1)',
  headerBlack: 'rgba(28, 28, 28, 1)',
  greyBlack: 'rgba(20, 20, 20, 1)',
  white: 'rgba(250, 250, 250, 1)',
  blackWithOpacity: 'rgba(250, 250, 250, 0.4)',
  blackWithOpacity05: 'rgba(250, 250, 250, 0.05)',
  errorRed: 'rgba(242, 0, 0, 1)',
  linearRed:
    'linear-gradient(239.18deg, rgba(175, 18, 65, 0.23) -111.92%, rgba(175, 18, 65, 0.24) -111.9%, rgba(175, 18, 65, 0) 77.95%)',
  linearYellow:
    'linear-gradient(239.18deg, rgba(218, 253, 0, 0.23) -111.92%, rgba(218, 253, 0, 0) 77.95%)',
  titleRed: 'rgba(210, 41, 92, 1)',
  titleRed059: 'rgba(210, 41, 92, 0.59)',
  titleWhite: 'rgba(255, 255, 255, 1)',
  titleGrey: 'rgba(255, 255, 255, 0.53)',
  listButtonLinearRed:
    'linear-gradient(239.18deg, rgba(211, 0, 0, 0.0483), rgba(175, 18, 18, 0.0504), rgba(211, 0, 0, 0))',
  listButtonLinearGreen:
    'linear-gradient(239.18deg, rgba(142, 253, 0, 0.0483), rgba(142, 253, 0, 0))',
  listButtonTextRed: 'rgba(217, 40, 40, 0.66)',
  listButtonTextGreen: 'rgba(119, 190, 29, 0.66)',
  inputBorder: 'rgba(250, 250, 250, 0.1)',
  inputBorderFocus: 'rgba(250, 250, 250, 0.2)',
  inputText: 'rgba(250, 250, 250, 0.9)',
  inputPlaceholder: 'rgba(250, 250, 250, 0.3)',
  inputBackground: 'rgba(18, 18, 18, 0.9)',
};
