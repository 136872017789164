import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { logOut, setUserLanguage, setUserTheme } from '../../services/Saga/loginSaga/actions';
import './styles/index.scss';
import { ROLES } from '../../pages/AdminPanel/utils/formatForTables';
import ROUTES from '../../routes/route';
import { toggleMenu } from '../../services/Redux/headerReducer/action';

// Components
import Dropdown from '../Dropdown/Dropdown';
import HeaderBackButton from './components/HeaderBackButton';

export const Header = ({ title, goBack, selected }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.loginReducer.user);
  const isMenuHidden = useSelector(state => state.headerReducer.isMenuHidden);

  const languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'Norsk', value: 'nor' }
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    // Set initial theme from user settings
    if (user?.theme) {
      document.documentElement.setAttribute('data-theme', user.theme);
    }
  }, [user]);

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
    dispatch(setUserLanguage(value));
  };

  const handleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const handleLogout = () => {
    dispatch(logOut(navigate));
  };

  const onTitlePress = (key) => {
    navigate(key);
  };

  const handleToggleMenu = () => {
    dispatch(toggleMenu(!isMenuHidden));
  };

  const handleThemeToggle = () => {
    const newTheme = user?.theme === 'dark' ? 'light' : 'dark';
    dispatch(setUserTheme(newTheme));
  };

  return (
    <header className="main-header">
      <div className="header-wrapper">
        <div className="header-left">
          {goBack ? (
            <HeaderBackButton 
              title={goBack.title} 
              onClick={goBack.onClick} 
            />
          ) : (
            user?.roles[0] !== ROLES.STORE_MANAGER && (
              <nav className="header-nav">
                <button 
                  className={`nav-button ${selected === 'HOME' ? 'selected' : ''}`}
                  onClick={() => onTitlePress(ROUTES.DASHBOARD.HOME)}
                >
                  {t('dashboard')}
                </button>

                {(user?.roles[0] === ROLES.ADMIN ||
                  user?.roles[0] === ROLES.MALL_MANAGER ||
                  user?.roles[0] === ROLES.MALL_OWNER) && (
                  <button 
                    className={`nav-button ${selected === 'LIST' ? 'selected' : ''}`}
                    onClick={() => onTitlePress(ROUTES.REPORTING_LIST)}
                  >
                    {t('reporting_List')}
                  </button>
                )}

                {user?.roles[0] === ROLES.ADMIN && (
                  <button 
                    className={`nav-button ${selected === 'ADMIN' ? 'selected' : ''}`}
                    onClick={() => onTitlePress(ROUTES.ADMIN.MALLS.LIST)}
                  >
                    {t('admin_Panel')}
                  </button>
                )}
              </nav>
            )
          )}
          {title && <h1 className="header-title">{title}</h1>}
        </div>

        <div className="header-actions">
          <button 
            className="nav-button"
            onClick={handleFullScreen}
          >
            {t('fullscreen')}
          </button>
          
          <button 
            className="nav-button"
            onClick={handleThemeToggle}
          >
            {user?.theme === 'dark' ? t('dark_mode') : t('light_mode')}
          </button>
          
          <Dropdown
            trigger={
              <button className="nav-button">
                {t('language')}
              </button>
            }
            items={languageOptions}
            onSelect={handleLanguageChange}
            value={selectedLanguage}
          />

          <button 
            className={`nav-button ${!isMenuHidden ? 'selected' : ''}`}
            onClick={handleToggleMenu}
          >
            {t('menu')}
          </button>

          <button 
            className="nav-button"
            onClick={handleLogout}
          >
            {t('sign_out')}
          </button>
        </div>
      </div>
    </header>
  );
};
