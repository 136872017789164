import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IncomeTableContext } from '../../IncomeTableContext';
import './styles.scss';
import Sort from '../../../../assets/icons/Sort';
import ToggleableEye from '../ToggleableEye';
import { getShouldDisplayDiff } from '../../utils/getDiff';
import { TableContext } from '../Table/TableContext';
import dayjs from 'dayjs';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import HeaderColumnItem from './HeaderColumnItem';

// Sort columns from oldest to most recent (left to right)
const sortColumnsByDate = (columns) => {
  return [...columns].sort((a, b) => {
    if (a.isDiff || b.isDiff) return 0;
    const dateA = dayjs(a.from);
    const dateB = dayjs(b.from);
    return dateA.valueOf() - dateB.valueOf(); // Oldest first
  });
};

function TableHeader() {
  const {
    toggleColumnVisibility,
    changeColumnSort,
    selectedColumn,
  } = useContext(IncomeTableContext);
  const {
    tableUID,
    isEdit,
    shownColumns,
    numbersDiffColumn,
    percentageDiffColumn,
    shouldDisplayAdd
  } = useContext(TableContext);

  const shouldDisplayDiff = getShouldDisplayDiff(shownColumns);
  const shouldShowNumberDiff =
    shouldDisplayDiff && (isEdit || numbersDiffColumn?.isVisible);
  const shouldShowPercentageDiff =
    shouldDisplayDiff && (isEdit || percentageDiffColumn?.isVisible);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const handleDragStart = ({ active }) => {
    setActiveColumnUid(active.id);
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = shownColumns.findIndex((x) => x.columnUID === active.id);
      const newIndex = shownColumns.findIndex((x) => x.columnUID === over.id);

      const newColumns = arrayMove(shownColumns, oldIndex, newIndex);
      // After manual reordering, maintain the new order
      changeColumnSort(tableUID, newColumns);
    }

    setActiveColumnUid(null);
  };

  const [activeColumnUid, setActiveColumnUid] = React.useState(null);

  // Sort columns chronologically unless they've been manually reordered
  const sortedColumns = React.useMemo(() => {
    return sortColumnsByDate(shownColumns);
  }, [shownColumns]);

  if (!shownColumns) return <></>;
  return (
    <div className='TableHeader'>
      <div>
        <div style={{ minWidth: 130 }} />
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={sortedColumns} strategy={rectSortingStrategy}>
            {sortedColumns
              .filter((col) => !col.isDiff)
              .map((col) => (
                <HeaderColumnItem key={col.columnUID} column={col} />
              ))}
          </SortableContext>
          <DragOverlay>
            {activeColumnUid ? (
              <HeaderColumnItem
                column={sortedColumns.find((x) => x.columnUID === activeColumnUid)}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
        {shouldShowNumberDiff && numbersDiffColumn && (
          <div className='TableHeader__column'>
            {isEdit && (
              <ToggleableEye
                isDisabled={!numbersDiffColumn.isVisible}
                onClick={() =>
                  toggleColumnVisibility(tableUID, numbersDiffColumn.columnUID)
                }
              />
            )}
            <div
              className='TableHeader__column__name'
              style={{ opacity: !numbersDiffColumn.isVisible ? 0.6 : 1 }}
            >
              <span>Diff</span>
            </div>
          </div>
        )}
        {shouldShowPercentageDiff && percentageDiffColumn && (
          <div className='TableHeader__column'>
            {isEdit && (
              <ToggleableEye
                isDisabled={!percentageDiffColumn.isVisible}
                onClick={() =>
                  toggleColumnVisibility(
                    tableUID,
                    percentageDiffColumn.columnUID,
                  )
                }
              />
            )}
            <div
              className='TableHeader__column__name'
              style={{ opacity: !percentageDiffColumn.isVisible ? 0.6 : 1 }}
            >
              <span>Diff %</span>
            </div>
          </div>
        )}
        {shouldDisplayAdd && <div className='TableHeader__column' />}
      </div>
    </div>
  );
}

export default TableHeader;
