import React from 'react';

const useOutsideClick = (callback) => {
  const ref = React.useRef();
  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    const handleClick = (e) => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        return;
      }
      
      if (ref.current && !ref.current.contains(e.target)) {
        e.stopPropagation();
        callback(e);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [callback]);

  return ref;
};

export default useOutsideClick;
