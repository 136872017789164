import React, { createContext, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SORT,
  defaultTables,
  generateDefaultTable,
  standartColumnProperties,
} from './const';
import generateUID from './utils/generateUID';
import { cloneDeep } from 'lodash';
import changeSavedTables from './utils/changeSavedTables';
import { getTotalIncomeAmount } from '../../services/Saga/getTotalIncomeAmount/actions';
import dayjs from 'dayjs';
import fillTable from './utils/fillTables';
import { formatTablesSettingsToIncludeSortedMalls } from './utils/sortTable';
import axiosInstance from '../../services/Axios/axiosInstance';
import { clearTotalIncomeAmount } from '../../services/Redux/totalIncomeCardsReducer/action';

export const IncomeTableContext = createContext({
  columns: null,
  tablesSettings: null,
  isEditing: false,
  selectedTable: null,
  shouldShowPercentageDiff: false,
  shouldShowNumberDiff: false,
  lastVisibleTable: null,
  animation: null,
  setAnimation: () => {},
  setLastVisibleTable: () => {},
  toggleEditing: () => {},
  selectTable: () => {},
  changeColumnType: () => {},
  toggleColumnTax: () => {},
  toggleStoreVisible: () => {},
  toggleMallVisible: () => {},
  removeColumn: () => {},
  addColumn: () => {},
  addTable: () => {},
  toggleSort: () => {},
  deleteTable: () => {},
  changeColumnDates: () => {},
  toggleColumnVisibility: () => {},
  changeColumnSort: () => {},
  updateFilters: () => {},
  moveTable: () => {},
  toggleMallMinimized: () => {},
  selectedColumn: null,
  setSelectedColumn: () => {},
  isEditorOpen: false,
  setIsEditorOpen: () => {},
  activeEditorTab: 'picker',
  setActiveEditorTab: () => {},
});

export const IncomeTableProvider = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.loginReducer.user);
  const [selectedColumn, setSelectedColumn] = React.useState(null);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [activeEditorTab, setActiveEditorTab] = useState('picker');

  const totalIncomeAmount = useSelector(
    (state) => state.totalIncomeCardReducer.totalIncomeAmount,
  );

  const [tablesSettings, setTablesSettings] = React.useState(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [selectedTable, setSelectedTable] = React.useState(null);
  const [lastVisibleTable, setLastVisibleTable] = React.useState(null);
  const [animation, setAnimation] = React.useState(null);

  const changeTables = (newTables) => {
    setTablesSettings(newTables);
    changeSavedTables(newTables);
  };

  const changeTableSettings = (tableUID, newTable) => {
    const copiedTables = cloneDeep(tablesSettings);
    copiedTables[tableUID] = newTable;
    changeTables(copiedTables);
  };

  React.useEffect(() => {
    const getTable = async () => {
      if (!user?.id) return;

      const userx = await axiosInstance.get(`/users/info`).catch(() => user);

      let copiedSavedIncomeTablesSettings = cloneDeep(null); // null;
      try {
        copiedSavedIncomeTablesSettings = cloneDeep(JSON.parse(userx.settings));
      } catch {
        copiedSavedIncomeTablesSettings = cloneDeep(userx.settings || {});
      }
      setLastVisibleTable(
        Object.keys(copiedSavedIncomeTablesSettings)?.[0] || null,
      );

      if (copiedSavedIncomeTablesSettings) {
        Object.keys(copiedSavedIncomeTablesSettings).forEach((tableUID) => {
          copiedSavedIncomeTablesSettings[tableUID].columns.forEach((col) => {
            if (col.isYesterday) {
              col.from = dayjs().add(-1, 'day').format('YYYY-MM-DD');
              col.to = dayjs().add(-1, 'day').format('YYYY-MM-DD');
            }

            if (col.isThisMonth) {
              col.from = dayjs().endOf('month').format('YYYY-MM-DD');
              col.to = dayjs().add(-1, 'day').format('YYYY-MM-DD');
            }

            if (col.isLastMonth) {
              col.from = dayjs()
                .add(-1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD');
              col.to = dayjs()
                .add(-1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD');
            }

            if (col.isAccumulated) {
              col.from = dayjs().startOf('year').format('YYYY-MM-DD');
              col.to = dayjs().add(-1, 'day').format('YYYY-MM-DD');
            }
          });
        });
      }
      let newTables =
        userx.settings.length > 2
          ? copiedSavedIncomeTablesSettings
          : defaultTables;

      newTables = Object.fromEntries(
        Object.entries(newTables).filter(([uid, table]) => {
          return table.columns.some((x) => x.from);
        }),
      );
      changeTables(newTables);
      Object.keys(newTables).forEach((tableUID) =>
        dispatch(
          getTotalIncomeAmount({
            columns: newTables[tableUID].columns
              .filter((col) => col.from)
              .map((col) => ({
                from: col.from,
                to: col.to,
              })),
            filters: newTables[tableUID].filters,
            tableUID,
          }),
        ),
      );
    };

    getTable();
  }, [user?.id]);

  React.useEffect(() => {
    if (!tablesSettings) return;
    const filledTables = fillTable(tablesSettings, totalIncomeAmount);
    setTablesSettings(filledTables);
    setTimeout(() => setAnimation(null), 120);
  }, [totalIncomeAmount]);

  React.useEffect(() => {
    dispatch(clearTotalIncomeAmount());
  }, []);

  const addColumn = async (tableUID, dates, createAtEnd = true) => {
    const {
      from,
      to,
      isAccumulated = false,
      isLastMonth = false,
      isThisMonth = false,
      isYesterday = false,
    } = dates;

    const clonedTables = cloneDeep(tablesSettings);
    const newColumn = {
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD'),
      isAccumulated,
      isLastMonth,
      isThisMonth,
      isYesterday,
      columnUID: generateUID(),
      ...standartColumnProperties,
    };
    const columns = cloneDeep(clonedTables[tableUID].columns);
    if (createAtEnd) {
      columns.splice(-2, 0, newColumn);
    } else columns.unshift(newColumn);
    clonedTables[tableUID] = { ...clonedTables[tableUID], columns };

    // First update the table data with the new column
    await dispatch(
      getTotalIncomeAmount({
        columns: clonedTables[tableUID].columns
          .filter((x) => !x.isDiff)
          .map((col) => ({
            from: col.from,
            to: col.to,
          })),
        filters: clonedTables[tableUID].filters,
        tableUID,
      }),
    );

    // Then update the table settings
    delete clonedTables[tableUID]?.malls;
    changeTables(clonedTables);
  };

  const updateFilters = (tableUID, filters) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    changedTablesSettings.filters = filters;
    dispatch(
      getTotalIncomeAmount({
        columns: changedTablesSettings.columns
          .filter((col) => col.from)
          .map((col) => ({
            from: col.from,
            to: col.to,
          })),
        filters: changedTablesSettings.filters,
        tableUID,
      }),
    );
    changeTableSettings(tableUID, changedTablesSettings);
  };

  const changeColumnDates = (tableUID, columnUID, dates) => {
    const {
      from,
      to,
      isAccumulated = false,
      isLastMonth = false,
      isThisMonth = false,
      isYesterday = false,
    } = dates;

    const clonedTables = cloneDeep(tablesSettings);
    const changedColumnsSettings = clonedTables[tableUID].columns.find(
      (col) => col.columnUID === columnUID,
    );
    changedColumnsSettings.from = from.format('YYYY-MM-DD');
    changedColumnsSettings.to = to.format('YYYY-MM-DD');
    changedColumnsSettings.isAccumulated = isAccumulated;
    changedColumnsSettings.isLastMonth = isLastMonth;
    changedColumnsSettings.isThisMonth = isThisMonth;
    changedColumnsSettings.isYesterday = isYesterday;

    dispatch(
      getTotalIncomeAmount({
        columns: clonedTables[tableUID].columns
          .filter((x) => !x.isDiff)
          .map((col) => ({
            from: col.from,
            to: col.to,
          })),
        tableUID,
      }),
    );
    delete clonedTables[tableUID]?.malls;
    changeTables(clonedTables);
  };

  const removeColumn = async (tableUID, columnUID) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];

    // First update the table data without the column
    await dispatch(
      getTotalIncomeAmount({
        columns: changedTablesSettings.columns
          .filter((x) => x.columnUID !== columnUID)
          .filter((x) => !x.isDiff)
          .map((col) => ({
            from: col.from,
            to: col.to,
          })),
        filters: changedTablesSettings.filters,
        tableUID,
      }),
    );

    // Then remove the column from settings
    changedTablesSettings.columns = changedTablesSettings.columns.filter(
      (col) => col.columnUID !== columnUID,
    );
    delete changedTablesSettings?.malls;
    changeTables(copiedTablesSettings);
  };

  const toggleColumnVisibility = (tableUID, columnUID) => {
    console.log('toggleColumnVisibility called with:', { tableUID, columnUID });
    const clonedTables = cloneDeep(tablesSettings);
    const changedColumnsSettings = clonedTables[tableUID].columns.find(
      (col) => col.columnUID === columnUID,
    );
    console.log('Found column:', changedColumnsSettings);
    if (!changedColumnsSettings) {
      console.error('Column not found:', columnUID);
      return;
    }
    changedColumnsSettings.isVisible = !changedColumnsSettings.isVisible;
    console.log('Updated visibility to:', changedColumnsSettings.isVisible);

    // Force a re-render by creating a new column array
    clonedTables[tableUID].columns = [...clonedTables[tableUID].columns];
    changeTableSettings(tableUID, clonedTables[tableUID]);
  };

  const moveTable = (direction) => {
    const clonedTables = cloneDeep(tablesSettings);
    const tableEntries = Object.entries(clonedTables);
    let selectedTableIndex = -1;
    const selectedTableObj = cloneDeep(
      tableEntries.find(([tableUID], index) => {
        const t = tableUID === selectedTable;
        if (t) selectedTableIndex = index;
        return t;
      }),
    );
    const newIndex = selectedTableIndex + (direction === 'down' ? 1 : -1);
    tableEntries[selectedTableIndex] = cloneDeep(tableEntries[newIndex]);
    tableEntries[newIndex] = selectedTableObj;
    const newTables = Object.fromEntries(tableEntries);

    changeTables(newTables);
  };

  const addTable = () => {
    const tableUID = generateUID();
    const newTable = generateDefaultTable();
    changeTableSettings(tableUID, newTable);
    dispatch(
      getTotalIncomeAmount({
        columns: newTable.columns
          .filter((x) => !x.isDiff)
          .map((col) => ({
            from: col.from,
            to: col.to,
          })),
        filters: newTable.filters,
        tableUID,
      }),
    );
  };

  const deleteTable = useCallback(async (tableId) => {
    try {
      const copiedTables = cloneDeep(tablesSettings);
      delete copiedTables[tableId];
      
      // Update tables state
      setTablesSettings(copiedTables);
      changeSavedTables(copiedTables);
      
      // Clear selected table if it was the one deleted
      setSelectedTable(null);

      return true;
    } catch (error) {
      console.error('Error deleting table:', error);
      return false;
    }
  }, [tablesSettings]);

  const toggleStoreVisible = (tableUID, storeId) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    changedTablesSettings.hiddenStores =
      changedTablesSettings.hiddenStores.find((id) => id === storeId)
        ? changedTablesSettings.hiddenStores.filter((id) => id !== storeId)
        : [...changedTablesSettings.hiddenStores, storeId];
    changeTableSettings(tableUID, changedTablesSettings);
  };

  const toggleMallVisible = (tableUID, mallId) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    changedTablesSettings.hiddenMalls = changedTablesSettings.hiddenMalls.find(
      (id) => id === mallId,
    )
      ? changedTablesSettings.hiddenMalls.filter((id) => id !== mallId)
      : [...changedTablesSettings.hiddenMalls, mallId];
    changeTableSettings(tableUID, changedTablesSettings);
  };

  const toggleMallMinimized = (tableUID, mallId) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    if (changedTablesSettings.minimizedMalls)
      changedTablesSettings.minimizedMalls =
        changedTablesSettings.minimizedMalls.find((id) => id === mallId)
          ? changedTablesSettings.minimizedMalls.filter((id) => id !== mallId)
          : [...changedTablesSettings.minimizedMalls, mallId];
    else changedTablesSettings.minimizedMalls = [mallId];
    changeTableSettings(tableUID, changedTablesSettings);
  };

  const toggleEditing = () => {
    if (!selectedColumn) {
      setIsEditing(!isEditing);
    }
  };

  const selectTable = (tableUID) => {
    setSelectedTable(tableUID);
  };

  const toggleColumnTax = (tableUID, columnUID, tax) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    const changedColumnsSettings = changedTablesSettings.columns.find(
      (col) => col.columnUID === columnUID,
    );
    changedColumnsSettings.tax = tax;
    changeTableSettings(tableUID, changedTablesSettings);
  };

  const changeColumnSort = (tableUID, newColumns) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    changedTablesSettings.columns = newColumns;
    changeTableSettings(tableUID, changedTablesSettings);
  };

  const changeColumnType = (tableUID, columnUID, type) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    const changedColumnsSettings = changedTablesSettings.columns.find(
      (col) => col.columnUID === columnUID,
    );
    changedColumnsSettings.type = type;
    if (type === 'averageTransaction') {
      changedColumnsSettings.tax = null;
    } else if (!changedColumnsSettings.tax) {
      changedColumnsSettings.tax = 'excluded';
    }
    changeTableSettings(tableUID, changedTablesSettings);
  };

  const toggleSort = (tableUID, columnUID) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];

    if (columnUID !== changedTablesSettings.sortedByColumnUID) {
      changedTablesSettings.sort = SORT[1];
      changedTablesSettings.sortedByColumnUID = columnUID;
    } else {
      const sortIndex = SORT.findIndex((x) => x === changedTablesSettings.sort);
      changedTablesSettings.sort =
        SORT[sortIndex + 1 >= SORT.length ? 0 : sortIndex + 1];
      changedTablesSettings.sortedByColumnUID = columnUID;
    }
    changeTableSettings(tableUID, changedTablesSettings);
  };

  return (
    <IncomeTableContext.Provider
      value={{
        tablesSettings: tablesSettings
          ? formatTablesSettingsToIncludeSortedMalls(
              tablesSettings,
              (tableUID) => isEditing && tableUID === selectedTable,
            )
          : {},
        isEditing,
        selectedTable,
        lastVisibleTable,
        animation,
        setAnimation,
        setLastVisibleTable,
        toggleEditing,
        selectTable,
        changeColumnType,
        toggleColumnTax,
        toggleStoreVisible,
        toggleMallVisible,
        removeColumn,
        addColumn,
        addTable,
        toggleSort,
        deleteTable,
        changeColumnDates,
        toggleColumnVisibility,
        changeColumnSort,
        updateFilters,
        moveTable,
        toggleMallMinimized,
        selectedColumn,
        setSelectedColumn,
        isEditorOpen,
        setIsEditorOpen,
        activeEditorTab,
        setActiveEditorTab,
      }}
    >
      {children}
    </IncomeTableContext.Provider>
  );
};
