import React from 'react';
import { useTranslation } from 'react-i18next';
import TrashIcon from '../../../../../../assets/icons/TrashIcon';
import './styles.scss';

const EditorHeader = ({
  activeTab,
  onTabChange,
  onDeleteTable,
}) => {
  const { t } = useTranslation();

  const tabs = [
    { key: 'picker', label: t('editor_tab_calendar') },
    { key: 'presets', label: t('editor_tab_presets') },
    { key: 'types', label: t('editor_tab_type') },
    { key: 'tax', label: t('editor_tab_vat') },
  ];

  return (
    <div className="editor-header">
      <div className="editor-header__tabs">
        {tabs.map(tab => (
          <button
            key={tab.key}
            type="button"
            className={`editor-header__tab ${activeTab === tab.key ? '--active' : ''}`}
            onClick={() => onTabChange(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <button
        type="button"
        className="editor-header__delete"
        onClick={onDeleteTable}
      >
        <TrashIcon />
      </button>
    </div>
  );
};

export default EditorHeader; 