import React, { useState } from 'react';
import './styles.scss';

const Dropdown = ({ trigger, items, onSelect, value }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTriggerClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSelect = (value) => {
    onSelect(value);
    setIsOpen(false);
  };

  return (
    <div className="custom-dropdown">
      <div onClick={handleTriggerClick}>
        {trigger}
      </div>
      
      {isOpen && (
        <div className="dropdown-content">
          {items.map((item) => (
            <div
              key={item.value}
              onClick={() => handleSelect(item.value)}
              className={`dropdown-item ${item.value === value ? 'selected' : ''}`}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown; 