import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './styles.scss';

const TypeSelector = ({ selectedType, onSelect }) => {
  const { t } = useTranslation();

  const types = [
    {
      key: 'income',
      label: t('income'),
      description: t('income_description'),
      icon: '💰'
    },
    {
      key: 'averageTransaction',
      label: t('average_transaction'),
      description: t('average_transaction_description'),
      icon: '📊'
    },
    {
      key: 'transactions',
      label: t('transactions'),
      description: t('transactions_description'),
      icon: '🔄'
    }
  ];

  return (
    <div className="type-selector">
      <div className="type-selector__grid">
        {types.map(type => (
          <button
            key={type.key}
            type="button"
            className={classNames('type-selector__item', {
              '--active': selectedType === type.key
            })}
            onClick={() => onSelect(type.key)}
          >
            <div className="type-selector__item-content">
              <span className="type-selector__item-icon">{type.icon}</span>
              <div className="type-selector__item-text">
                <span className="type-selector__item-label">{type.label}</span>
                <span className="type-selector__item-description">{type.description}</span>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default TypeSelector; 