import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  logOutRed,
  setIsLoading,
  setUser,
} from '../../Redux/loginReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';
import ROUTES from '../../../routes/route';
import showNotification from '../../../utils/showNotification';
import { getI18n } from 'react-i18next';
import { logTokenInfo } from '../../../utils/tokenDebug';
import { jwtDecode } from 'jwt-decode';

export function* watcherLogin() {
  yield takeEvery(ActionTypes.LOGIN, workerLogin);
}
export function* watcherLogOut() {
  yield takeEvery(ActionTypes.LOG_OUT, workerLogOut);
}
export function* watcherGetUserInfo() {
  yield takeEvery(ActionTypes.GET_USER_INFO, workerGetUserInfo);
}
export function* watcherSetUserLanguage() {
  yield takeEvery(ActionTypes.SET_USER_LANGUAGE, workerSetUserLanguage);
}
export function* watcherSetUserTheme() {
  yield takeEvery(ActionTypes.SET_USER_THEME, workerSetUserTheme);
}

export function* workerLogin({ payload }) {
  const logs = JSON.parse(localStorage.getItem('sessionLogs') || '[]');
  logs.push({
    timestamp: new Date().toISOString(),
    message: 'Login attempt',
    email: payload.email
  });
  localStorage.setItem('sessionLogs', JSON.stringify(logs));

  const body = {
    email: payload.email.trim(),
    password: payload.password.trim(),
  };
  try {
    const res = yield call(axiosInstance.post, `/auth/sign-in`, body);
    if (res.accessToken && res.refreshToken) {
      logs.push({
        timestamp: new Date().toISOString(),
        message: 'Login successful',
        debug: {
          hasTokens: {
            access: !!res.accessToken,
            refresh: !!res.refreshToken
          },
          tokenInfo: (() => {
            try {
              const decoded = jwtDecode(res.accessToken);
              return {
                expiresAt: new Date(decoded.exp * 1000).toISOString(),
                timeUntilExpiry: Math.floor((decoded.exp * 1000 - Date.now()) / 1000)
              };
            } catch (e) {
              return { error: 'Failed to decode token' };
            }
          })()
        }
      });
      localStorage.setItem('sessionLogs', JSON.stringify(logs));

      yield put(setUser({
        accessToken: res.accessToken,
        refreshToken: res.refreshToken,
        user: res.user
      }));
      payload.navigate(ROUTES.DASHBOARD.HOME);
      const userInfo = yield call(axiosInstance.get, `/users/info`);
      yield put(setUser({ user: userInfo }));
      
      const theme = userInfo.theme || 'light';
      document.documentElement.setAttribute('data-theme', theme);
      
      logTokenInfo();
    }
  } catch (e) {
    logs.push({
      timestamp: new Date().toISOString(),
      message: 'Login failed',
      error: {
        status: e?.response?.status,
        data: e?.response?.data
      }
    });
    localStorage.setItem('sessionLogs', JSON.stringify(logs));

    if (e?.response?.status === 400) {
      yield showNotification({
        type: 'error',
        content: getI18n().t('email_password'),
      });
    }
  }
}

export function* workerLogOut({ payload }) {
  const logs = JSON.parse(localStorage.getItem('sessionLogs') || '[]');
  const state = yield select(state => state.loginReducer);
  
  logs.push({
    timestamp: new Date().toISOString(),
    message: 'Logout initiated',
    debug: {
      hasTokens: {
        access: !!state.accessToken,
        refresh: !!state.refreshToken
      },
      tokenInfo: (() => {
        try {
          const decoded = jwtDecode(state.accessToken);
          return {
            expiresAt: new Date(decoded.exp * 1000).toISOString(),
            timeUntilExpiry: Math.floor((decoded.exp * 1000 - Date.now()) / 1000)
          };
        } catch (e) {
          return { error: 'Failed to decode token' };
        }
      })()
    }
  });
  localStorage.setItem('sessionLogs', JSON.stringify(logs));

  const currentTheme = state.user?.theme || 'light';
  yield put(logOutRed());
  document.documentElement.setAttribute('data-theme', currentTheme);
  payload('/login');
}

export function* workerGetUserInfo() {
  yield put(setIsLoading(true));
  const token = yield select((state) => state?.loginReducer?.accessToken);
  if (token) {
    const res = yield call(axiosInstance.get, `/users/info`);
    yield put(setUser({ user: res }));
  }
  yield put(setIsLoading(false));
}

export function* workerSetUserLanguage({ payload }) {
  const res = yield call(axiosInstance.post, `/users/lang`, { lang: payload });
}

export function* workerSetUserTheme({ payload }) {
  try {
    // Update theme in database
    const themeResponse = yield call(axiosInstance.post, `/users/theme`, { theme: payload });
    console.log('Theme update response:', themeResponse);
    
    if (!themeResponse || themeResponse.error) {
      console.error('Failed to update theme in database:', themeResponse);
      return;
    }
    
    // Get updated user info and update store
    const userInfo = yield call(axiosInstance.get, `/users/info`);
    console.log('Updated user info:', userInfo);
    
    if (userInfo?.theme !== payload) {
      console.error('Theme mismatch - DB:', userInfo?.theme, 'Requested:', payload);
      return;
    }
    
    yield put(setUser({ user: userInfo }));
    
    // Update DOM
    document.documentElement.setAttribute('data-theme', payload);
  } catch (e) {
    console.error('Failed to set theme:', e?.response?.data || e);
  }
}
