import React, { useContext, useRef, useEffect } from 'react';
import { TableContext } from '../Table/TableContext';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IncomeTableContext } from '../../IncomeTableContext';
import MinusCircle from '../../../../assets/icons/MinusCircle';
import { useTranslation } from 'react-i18next';
import { formatDateRange } from '../../utils/formatDateRange';

function HeaderColumnItem({ column }) {
  const { t } = useTranslation();
  const { tableUID, isEdit } = useContext(TableContext);
  const textContentRef = useRef(null);
  const buttonRef = useRef(null);
  const { 
    changeColumnType, 
    toggleColumnTax, 
    removeColumn,
    setSelectedColumn,
    setIsEditorOpen,
    setActiveEditorTab,
    selectTable
  } = useContext(IncomeTableContext);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: column.columnUID, disabled: !isEdit });

  const style = {
    transform: CSS.Transform.toString(transform),
    width: '100%',
    transition,
  };

  const openEditor = (column, tab) => {
    selectTable(tableUID);
    setSelectedColumn(column.columnUID);
    setActiveEditorTab(tab);
    setIsEditorOpen(tableUID);
  };

  const handleDateClick = (e, column) => {
    e.stopPropagation();
    openEditor(column, 'picker');
  };

  const handleTypeClick = (e, column) => {
    e.stopPropagation();
    openEditor(column, 'types');
  };

  const handleTaxClick = (e, column) => {
    e.stopPropagation();
    openEditor(column, 'tax');
  };

  const renderColumnContent = () => {
    if (column.isDiff) {
      return (
        <div className="column-display" data-type="diff">
          <div className="column-display__diff">
            <span>{column.diffType === 'numbers' ? t('Diff') : t('Diff %')}</span>
          </div>
        </div>
      );
    }

    return (
      <div className="column-display">
        <button 
          ref={buttonRef}
          type="button"
          className="column-display__button"
          onClick={(e) => handleDateClick(e, column)}
        >
          <div ref={textContentRef} className="text-content">
            {formatDateRange(column.from, column.to, t)}
          </div>
        </button>
        <button 
          type="button"
          className="column-display__button"
          onClick={(e) => handleTypeClick(e, column)}
        >
          {column.type ? t(column.type) : t('income')}
        </button>
        {column.type !== 'averageTransaction' && (
          <button 
            type="button"
            className="column-display__button"
            onClick={(e) => handleTaxClick(e, column)}
          >
            {column.tax === 'included' ? t('vat_display_included') : t('vat_display_excluded')}
          </button>
        )}
      </div>
    );
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className={`TableHeader__column ${isEdit ? 'TableHeader__column--edit' : ''}`}>
        {isEdit && (
          <div
            className='TableHeader__column__hide'
            onClick={() => removeColumn(tableUID, column.columnUID)}
          >
            <MinusCircle />
          </div>
        )}
        {renderColumnContent()}
      </div>
    </div>
  );
}

export default HeaderColumnItem;
