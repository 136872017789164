import * as React from 'react';
const MinusCircle = ({ className = 'icon icon-idle', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    className={className}
    {...props}
  >
    <circle
      cx="10"
      cy="10"
      r="7"
      stroke="currentColor"
      strokeWidth="1.2"
      fill="none"
    />
    <path
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      d="M7 10h6"
    />
  </svg>
);
export default MinusCircle; 