import * as React from 'react';

const ExportNew = ({ className = 'icon-idle', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    {...props}
  >
    <path
      d="M10 2.5V13.333M10 13.333L5.833 9.167M10 13.333L14.167 9.167M2.5 16.667H17.5"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ExportNew;
