import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import reportWebVitals from './reportWebVitals';
import { store } from './services';
import { APP_VERSION } from './utils/version';
import './styles/main.scss';
import './i18n';

// Add a flag to prevent multiple reloads
let hasCheckedVersion = false;

const persistor = persistStore(store, null, () => {
  if (hasCheckedVersion) return;
  hasCheckedVersion = true;
  
  const storedVersion = localStorage.getItem('appVersion');
  
  if (storedVersion !== APP_VERSION) {
    // Clear storage without immediate reload
    persistor.purge().then(() => {
      localStorage.clear();
      localStorage.setItem('appVersion', APP_VERSION);
      
      // Only reload if we actually cleared something
      if (storedVersion) {
        window.location.reload();
      }
    });
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<></>}>
            <App />
          </Suspense>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
