import { useEffect, useRef } from 'react';
import { checkSessionStatus } from '../tokenUtils';
import { handleSessionExpiration } from '../../services/Axios/setupInterceptors';
import { useLocation } from 'react-router-dom';
import ROUTES from '../../routes/route';

export const useSessionCheck = () => {
  const lastCheckRef = useRef(Date.now());
  const checkIntervalMs = 1000;
  const location = useLocation();

  useEffect(() => {
    // Don't set up listeners if we're on the login page
    if (location.pathname === ROUTES.AUTH.LOGIN) {
      return;
    }

    const handleInteraction = async (e) => {
      // Skip checks for input events
      if (e.target.tagName === 'INPUT') return;
      
      // Throttle checks
      const now = Date.now();
      if (now - lastCheckRef.current < checkIntervalMs) return;
      lastCheckRef.current = now;

      const isValid = checkSessionStatus();
      if (!isValid) {
        await handleSessionExpiration({ 
          response: { 
            data: { code: 401005 } 
          }
        });
      }
    };

    document.addEventListener('click', handleInteraction);
    document.addEventListener('keydown', handleInteraction);

    return () => {
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('keydown', handleInteraction);
    };
  }, [location.pathname]);
};
