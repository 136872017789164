import React from 'react';
import Calendar from './Calendar';
import Presets from './Presets';
import TypeSelector from './TypeSelector';
import TaxSelector from './TaxSelector';

const EditingArea = ({
  activeTab,
  selectedColumn,
  onDateChange,
  onTypeChange,
  onTaxChange,
  onTabChange,
}) => {
  const renderContent = () => {
    if (!selectedColumn) {
      return null;
    }

    switch (activeTab) {
      case 'picker':
        return (
          <Calendar 
            dates={{
              from: selectedColumn.from,
              to: selectedColumn.to
            }}
            onDateChange={onDateChange}
            onTabChange={onTabChange}
          />
        );
      case 'presets':
        return (
          <Presets 
            selectedPreset={selectedColumn.preset}
            onSelect={(preset) => {
              if (preset.from && preset.to) {
                onDateChange({ from: preset.from, to: preset.to });
              }
            }}
          />
        );
      case 'types':
        return (
          <TypeSelector 
            selectedType={selectedColumn.type}
            onSelect={onTypeChange}
          />
        );
      case 'tax':
        return (
          <TaxSelector 
            selectedTax={selectedColumn.tax}
            onSelect={onTaxChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="editing-area">
      {renderContent()}
    </div>
  );
};

export default EditingArea; 