import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import Settings from '../../../../assets/icons/Settings';
import ExportNew from '../../../../assets/icons/ExportNew';
import FilterNew from '../../../../assets/icons/FilterNew';
import './styles.scss';

const ReportingSideMenu = () => {
  const { t } = useTranslation();

  return (
    <div className="side-menu side-menu--reporting">
      <div className="controls-group">
        <Tooltip title={t('filters')} placement="left">
          <FilterNew />
        </Tooltip>
        <Tooltip title={t('settings')} placement="left">
          <Settings />
        </Tooltip>
        <Tooltip title={t('export')} placement="left">
          <ExportNew />
        </Tooltip>
      </div>
    </div>
  );
};

export default ReportingSideMenu; 