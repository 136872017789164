import dayjs from 'dayjs';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Header } from '../../components/Header/Header';
import { getStoreIncome } from '../../services/Saga/storeIncome/actions';
import { Colors } from '../../utils/colors';
import './style.scss';
import MonthsComponent from '../../components/MonthsComponent';
import IncomeCalendarCell from './components/IncomeCalendarCell';
import YearPicker from '../../components/YearPicker';
import { ROLES } from '../AdminPanel/utils/formatForTables';
import ROUTES from '../../routes/route';
import { setFilters } from '../../services/Redux/reportingListReducer/action';
import { getDateRange } from '../../services/Redux/reportingListReducer/index';

export const Income = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState(null);
  const { state } = useLocation();
  const [filteredData, setFilteredData] = useState([]);
  const user = useSelector((state) => state.loginReducer.user);
  const dispatch = useDispatch();
  const storeIncome = useSelector(
    (state) => state.storeIncomeReducer.storeIncome,
  );
  const isStoreManager =
    user.roles.length && user.roles[0] === ROLES.STORE_MANAGER;
  const navigate = useNavigate();
  const { selectedMonth, selectedYear } = useSelector(state => state.reportingListReducer.filters);

  useEffect(() => {
    let filteredThisYear = [];
    const filteredPreviousYear = [];
    let filtered = [];
    const currentYear = date?.[0]?.$y || dayjs().$y;

    const arrayThisYear = storeIncome?.data?.filter(
      (incomeEntry) => dayjs(incomeEntry.date).get('year') == currentYear,
    );

    const arrayPreviousYear = storeIncome?.data?.filter(
      (incomeEntry) => dayjs(incomeEntry.date).get('year') == currentYear - 1,
    );

    const filteredArrayThisYear = arrayThisYear?.filter((incomeEntry) => {
      if (
        [53, 52].indexOf(incomeEntry?.week) > -1 &&
        dayjs(incomeEntry?.date).get('month') == 0
      )
        return false;
      return incomeEntry;
    });

    arrayPreviousYear?.push(
      ...arrayThisYear?.filter(
        (incomeEntry) =>
          [53, 52].indexOf(incomeEntry?.week) > -1 &&
          dayjs(incomeEntry?.date).get('month') == 0,
      ),
    );
    for (let index = 0; index <= 53; index++) {
      let arr = filteredArrayThisYear?.filter((incomeEntry) => incomeEntry.week == index);
      if (arr?.length) {
        const test = arr?.map((incomeEntry) => {
          return { ...incomeEntry, date: new Date(incomeEntry.date) };
        });
        filteredThisYear.push({
          key: index,
          data: test,
        });
      }
    }
    const has52WeekAnd1Week =
      filteredArrayThisYear?.filter((incomeEntry) => incomeEntry.week == 52).length > 0 &&
      filteredArrayThisYear?.filter((incomeEntry) => incomeEntry.week == 1).length > 0;

    if (has52WeekAnd1Week) {
      const filtered = filteredThisYear.filter((x) => x.key === 1);
      filteredThisYear = filteredThisYear.filter((x) => x.key !== 1);
      filteredThisYear.push(...filtered);
    }

    for (let index = 53; index > 0; index--) {
      let arr = arrayPreviousYear?.filter((incomeEntry) => incomeEntry.week == index);
      if (arr?.length) {
        const test = arr?.map((incomeEntry) => {
          return { ...incomeEntry, date: new Date(incomeEntry.date) };
        });
        filteredPreviousYear.push({
          key: index,
          data: test,
        });
      }
    }

    filtered = [...filteredPreviousYear.reverse(), ...filteredThisYear];
    setFilteredData(filtered);
  }, [storeIncome.data, date]);

  // Handle navigation state
  useEffect(() => {
    console.log('Income Page - State Initialization:', {
      page: 'Income',
      navigationState: state,
      selectedMonth,
      selectedYear,
      timestamp: new Date().toISOString()
    });

    // Only initialize from navigation state if we don't have existing filters
    if (state?.date?.[0]?.$d && (!selectedMonth || !selectedYear)) {
      const dateStart = dayjs(state.date[0].$d);
      const newMonth = {
        name: dateStart.format('MMM'),
        number: dateStart.month() + 1
      };
      
      console.log('Income Page - Initial Filter Setup:', {
        page: 'Income',
        newMonth,
        newYear: dateStart.year(),
        timestamp: new Date().toISOString()
      });
      
      dispatch(setFilters({
        selectedMonth: newMonth,
        selectedYear: dateStart.year()
      }));
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (!selectedMonth || !selectedYear) return;
  
    const [start, end] = getDateRange(selectedMonth, selectedYear);
    if (!start || !end) return;
  
    setDate([start, end]);
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    if (!date || !state?.store?.id && !user.storeId) return;

    dispatch(
      getStoreIncome({
        id: state?.store?.id || user.storeId,
        date,
      })
    );
  }, [date, state?.store?.id, user.storeId, dispatch]);

  const onStoreIncomeAdded = (weekIndex, index, res) => {
    setFilteredData((p) => {
      const copy = [...p];
      copy[weekIndex].data[index] = res;
      return copy;
    });
  };

  const onYearChange = useCallback((year) => {
    dispatch(setFilters({ selectedYear: year }));
  }, [dispatch]);

  return (
    <>
      <Header
        selected="INCOME"
        title={isStoreManager ? t('report_Income') : undefined}
        goBack={{
          title: t('reporting_List'),
          onClick: () => navigate(ROUTES.REPORTING_LIST),
        }}
      />
      <div className="mainContainer">
        <div className='contentContainer' style={{ backgroundColor: Colors.mainBlack }}>
          <div className='incomeSortingContainer'>
            <div className='componentColumn'>
              <div className='incomeInfo'>
                <div>
                  <span className='text'>Mall: </span>
                  <span className='name'>
                    {state?.store?.mallName || user?.store?.mall.name}
                  </span>
                </div>
                <div>
                  <span className='text'>Store: </span>
                  <span className='name'>
                    {state?.store?.name || user?.store?.name}
                  </span>
                </div>
              </div>
              <YearPicker
                selectedYear={selectedYear}
                onChange={onYearChange}
              />
              <MonthsComponent
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
              />
            </div>
          </div>
          <div className='scrollableContent'>
            {isStoreManager && !user.storeId ? (
              <h2>{t('no_store_assigned')}</h2>
            ) : (
              <div className='incomeWrapper'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <div className='incomeBodyContainer'>
                    <div className='weekDaysContainer'>
                      <text className='weekDays'>{t('monday')}</text>
                      <text className='weekDays'>{t('tuesday')}</text>
                      <text className='weekDays'>{t('wednesday')}</text>
                      <text className='weekDays'>{t('thursday')}</text>
                      <text className='weekDays'>{t('friday')}</text>
                      <text className='weekDays'>{t('saturday')}</text>
                      <text className='weekDays'>{t('sunday')}</text>
                    </div>
                  </div>
                  <div>
                    {filteredData.map((item, weekIndex) => (
                      <div key={weekIndex} className='incomeCalendarRow'>
                        <div className='incomeCalendarRow__first'>
                          <text className='incomeCalendarRow__first--excluded'>
                            {t('excluded')}
                          </text>
                          <text className='incomeCalendarRow__first--included'>
                            {t('included')}
                          </text>
                        </div>
                        <div
                          className='incomeCalendarRow__data'
                          style={
                            weekIndex === 0
                              ? { justifyContent: 'flex-end' }
                              : {}
                          }
                        >
                          {item.data.map((inputField, index) => (
                            <IncomeCalendarCell
                              key={index}
                              inputField={inputField}
                              storeInfo={state?.store || user?.store}
                              onIncomeAdded={(res) =>
                                onStoreIncomeAdded(weekIndex, index, res)
                              }
                            />
                          ))}
                        </div>
                        <div className='incomeCalendarRow__nameWeek'>
                          <text className='weekDays'>
                            {t('week')} {item.key}
                          </text>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
