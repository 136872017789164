import * as React from 'react';
const FilterNew = ({ className = 'icon-idle', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    className={className}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M11.875 10h5.625M2.5 10h5.625m-2.5 5h11.25m-11.25-10h11.25'
    />
  </svg>
);
export default FilterNew;
