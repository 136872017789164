import { call, put, takeEvery } from 'redux-saga/effects';
import { MAIN_URL } from '../../../utils/constants';
import {
  setAllMalls,
  setAllMallsUsers,
  setAllStores,
  setAllStoresGroup,
} from '../../Redux/filtersReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';

export function* watcherGetAllMalls() {
  yield takeEvery(ActionTypes.GET_ALL_MALLS, workerGetAllMalls);
}

export function* workerGetAllMalls({ payload }) {
  const res = yield call(
    axiosInstance.get,
    `/malls/availables?page=1&limit=100${
      payload?.mallId ? `&mallIds=${payload?.mallId}` : ''
    }${
      payload?.storeGroupsId ? `&storeGroupsIds=${payload?.storeGroupsId}` : ''
    }${payload?.storeId ? `&storeIds=${payload?.storeId}` : ''}${
      payload?.tax ? `&tax=${payload?.tax}` : ''
    }${payload?.order ? `&order=${payload?.order}` : ''}`,
  );
  yield put(setAllMalls(res));
}
export function* watcherGetAllMallsUsers() {
  yield takeEvery(ActionTypes.GET_ALL_MALLS_USERS, workerGetAllMallsUsers);
}

export function* workerGetAllMallsUsers() {
  const res = yield call(axiosInstance.get, `/malls/?page=1&limit=100`);
  yield put(setAllMallsUsers(res));
}
export function* watcherGetAllStores() {
  yield takeEvery(ActionTypes.GET_ALL_STORES, workerGetAllStores);
}

export function* workerGetAllStores({ payload }) {
  const res = yield call(
    axiosInstance.get,
    `/stores/availables?page=1&limit=100${
      payload?.mallId ? `&mallIds=${payload?.mallId}` : ''
    }${
      payload?.storeGroupsId ? `&storeGroupsIds=${payload?.storeGroupsId}` : ''
    }${payload?.storeId ? `&storeIds=${payload?.storeId}` : ''}${
      payload?.tax ? `&tax=${payload?.tax}` : ''
    }${payload?.order ? `&order=${payload?.order}` : ''}`,
  );
  yield put(setAllStores(res));
}

export function* watcherGetAllGroups() {
  yield takeEvery(ActionTypes.GET_ALL_STORES_GROUP, workerGetAllGroups);
}

export function* workerGetAllGroups() {
  const res = yield call(axiosInstance.get, `/store-groups`);
  yield put(setAllStoresGroup(res));
}
