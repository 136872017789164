import React from 'react';
import useStoresTable from './useStoresTable';
import { Colors } from '../../../../../utils/colors';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../../../components/Header/Header';
import AdminTable from '../../../components/AdminTable';
import { Tooltip } from '@mui/material';
import AdminTablesController from '../../../components/AdminTablesController';
import AddStore from '../../../components/modals/AddStore';
import AddNewButton from '../../../components/AddNewButton';
import SearchInput from '../../../components/SearchInput';
import { Link } from 'react-router-dom';
import {
  getStoreInfoLink,
  getStoreUsersLink,
  getUserInfoLink,
} from '../../../../../routes/route';

function StoresTable() {
  const {
    stores,
    searchText,
    isModalVisible,
    setIsModalVisible,
    setSearchText,
    onStoreCreated,
    pagination,
    onPageChange,
  } = useStoresTable();
  const { t } = useTranslation();

  const storesColumns = [
    {
      title: 'Nº',
      dataIndex: 'index',
      render: (text) => <span className='adminTable__grey'>{text}</span>,
      width: 60,
    },
    {
      title: t('adminTable.storeName'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div>
          <Link to={getStoreInfoLink(record.id)}>{text}</Link>
        </div>
      ),
      width: 140,
    },
    {
      title: t('adminTable.mall'),
      dataIndex: 'mall',
      render: (text) => <div>{text}</div>,
      width: 240,
    },
    {
      title: t('adminTable.storeManager'),
      dataIndex: 'storeManager',
      render: (text, record) => (
        <div>
          {text ? (
            <Link to={getUserInfoLink(record.storeManagerId)}>{text}</Link>
          ) : (
            '-'
          )}
        </div>
      ),
      width: 240,
    },
    {
      title: t('adminTable.status'),
      dataIndex: 'closed',
      render: (closed) => (
        <div>{closed ? t('adminTable.closed') : t('adminTable.active')}</div>
      ),
      width: 90,
    },
    {
      title: t('adminTable.tax'),
      dataIndex: 'tax',
      sorter: (a, b) => a.tax.localeCompare(b.tax),
      render: (text) => <div>{t(text.toLowerCase())}</div>,
      width: 90,
    },
  ];

  return (
    <>
      <Header selected="ADMIN" />
      <AddStore
        isOpen={isModalVisible}
        close={() => setIsModalVisible(false)}
        onStoreCreated={onStoreCreated}
      />
      <div className="mainContainer">
        <div>
          <AdminTable
            columns={storesColumns}
            dataSource={stores}
            pagination={pagination}
            onPaginationChange={onPageChange}
            header={
              <div style={{ margin: '16px 0' }}>
                <div className='adminTable__header'>
                  <AdminTablesController />
                  <AddNewButton
                    text={t('add_New_Store')}
                    onClick={() => setIsModalVisible(true)}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <SearchInput value={searchText} onChange={setSearchText} />
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
}

export default StoresTable;
