import React from 'react';
import { Routes } from './routes/Routes';
import 'antd/dist/reset.css';
import { ToastContainer } from 'react-toastify';
import { useSessionCheck } from './utils/hooks/useSessionCheck';

const App = () => {
  useSessionCheck();

  return (
    <div className='app-wrapper'>
      <Routes />
      <ToastContainer />
    </div>
  );
};

export default App;
