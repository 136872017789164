import { jwtDecode } from 'jwt-decode';
import { store } from '../services';

/**
 * Formats a duration in minutes into a human-readable string
 * @param {number} minutes - Total minutes
 * @returns {string} Formatted duration (e.g., "2 months", "3 hours", "30 minutes")
 */
export const formatDuration = (minutes) => {
  if (minutes >= 43200) { // 30 days
    const months = Math.round(minutes / 43200);
    return { value: months, unit: 'months' };
  } else if (minutes >= 1440) { // 24 hours
    const days = Math.round(minutes / 1440);
    return { value: days, unit: 'days' };
  } else if (minutes >= 60) {
    const hours = Math.round(minutes / 60);
    return { value: hours, unit: 'hours' };
  } else {
    return { value: minutes, unit: 'minutes' };
  }
};

/**
 * Gets the refresh token expiry time in minutes
 * @returns {number} Minutes until token expiry, or default of 30 minutes
 */
export const getRefreshTokenExpiryMinutes = () => {
  try {
    const { refreshToken } = store.getState().loginReducer;
    if (!refreshToken) return 30;

    const decoded = jwtDecode(refreshToken);
    const expiryTimeInSeconds = decoded.exp - decoded.iat;
    return Math.round(expiryTimeInSeconds / 60);
  } catch (error) {
    console.debug('Error calculating token expiry:', error);
    return 30;
  }
};

/**
 * Checks if a token is valid and not expired
 * @param {string} token - JWT token to check
 * @returns {boolean} True if token is valid and not expired
 */
export const isTokenValid = (token) => {
  if (!token) return false;
  
  try {
    const decoded = jwtDecode(token);
    return decoded.exp * 1000 > Date.now();
  } catch {
    return false;
  }
};

/**
 * Gets remaining time for a token in seconds
 * @param {string} token - JWT token to check
 * @returns {number} Seconds until expiry, or 0 if invalid/expired
 */
export const getTokenTimeRemaining = (token) => {
  if (!token) return 0;
  
  try {
    const decoded = jwtDecode(token);
    const remaining = Math.floor((decoded.exp * 1000 - Date.now()) / 1000);
    return remaining > 0 ? remaining : 0;
  } catch {
    return 0;
  }
};

/**
 * Gets the refresh token expiry time in a formatted string
 * @returns {string} Formatted duration (e.g., "2 months", "3 hours", "30 minutes")
 */
export const getRefreshTokenExpiryFormatted = () => {
  const minutes = getRefreshTokenExpiryMinutes();
  return formatDuration(minutes);
};

/**
 * Checks session status
 * @returns {boolean} True if session is valid, false otherwise
 */
export const checkSessionStatus = () => {
  const { accessToken, refreshToken } = store.getState().loginReducer;
  if (!accessToken || !refreshToken) return false;

  try {
    const decodedAccess = jwtDecode(accessToken);
    const decodedRefresh = jwtDecode(refreshToken);
    
    const accessExpired = decodedAccess.exp * 1000 <= Date.now();
    const refreshExpired = decodedRefresh.exp * 1000 <= Date.now();

    // Only return false (triggering alert) if both tokens are expired
    return !(accessExpired && refreshExpired);
  } catch {
    return false;
  }
};