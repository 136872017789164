import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IncomeTableContext } from '../../IncomeTableContext';
import { toggleMenu } from '../../../../services/Redux/headerReducer/action';
import FilterNew from '../../../../assets/icons/FilterNew';
import Settings from '../../../../assets/icons/Settings';
import Add from '../../../../assets/icons/Add';
import Delete from '../../../../assets/icons/Delete';
import ExportNew from '../../../../assets/icons/ExportNew';
import HideUp from '../../../../assets/icons/HideUp';
import HideDown from '../../../../assets/icons/HideDown';
import ExportProgress from '../ExportProgress';
import { useFilters } from '../Filters';
import isElementInViewport from '../../utils/isInViewport';
import exportTable from '../../utils/exportTable';
import './styles.scss';

const DashboardSideMenu = () => {
  const {
    selectedTable,
    toggleEditing,
    addTable,
    deleteTable,
    isEditing,
    tablesSettings,
    lastVisibleTable,
    setLastVisibleTable,
    moveTable,
    animation,
    updateFilters,
    selectedColumn,
  } = useContext(IncomeTableContext);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [progress, setProgress] = React.useState(0);
  const user = useSelector((state) => state.loginReducer.user);
  const isStoreManager = user?.roles?.[0] === 'STORE_MANAGER';
  const isMenuHidden = useSelector(state => state.headerReducer.isMenuHidden);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const { categories, getFilterLabel } = useFilters();
  const prevSelectedTable = useRef(selectedTable);

  useEffect(() => {
    // Only open menu if table was selected without a column being selected
    if (!prevSelectedTable.current && selectedTable && isMenuHidden && !selectedColumn) {
      dispatch(toggleMenu(false));
    }
    
    prevSelectedTable.current = selectedTable;
  }, [selectedTable, dispatch, isMenuHidden, selectedColumn]);

  React.useEffect(() => {
    const onScroll = () => {
      let foundVisible = false;
      if (tablesSettings) {
        Object.keys(tablesSettings).forEach((tableUID) => {
          if (foundVisible) return;
          if (isElementInViewport(document.getElementById(tableUID))) {
            setLastVisibleTable(tableUID);
            foundVisible = true;
          }
        });
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [tablesSettings, setLastVisibleTable]);

  const handleEdit = () => {
    if (!selectedTable) return;
    toggleEditing();
  };

  const handleExport = async () => {
    if (progress !== 0 || selectedTable || isStoreManager) return;
    setProgress(1);
    exportTable(tablesSettings, setProgress).finally(() => setProgress(0));
  };

  const handleDelete = () => {
    if (!selectedTable) return;
    deleteTable();
  };

  const isAddDisabled = selectedTable || 
    Object.keys(tablesSettings).length >= (isStoreManager ? 3 : 10);

  const handleAdd = () => {
    if (isAddDisabled) return;
    addTable();
  };

  const tableUIDs = Object.keys(tablesSettings);
  const disableExport = selectedTable || isStoreManager ? 'disabled' : '';
  const disableIfNoTable = selectedTable ? '' : 'disabled';

  const shouldDisableScrollUp = animation || 
    (selectedTable 
      ? tableUIDs.at(0) === selectedTable 
      : tableUIDs.at(0) === lastVisibleTable);

  const shouldDisableScrollDown = animation || 
    (selectedTable 
      ? tableUIDs.at(-1) === selectedTable 
      : tableUIDs.at(-1) === lastVisibleTable);

  const handleScrollUp = () => {
    if (shouldDisableScrollUp) return;
    if (selectedTable) {
      moveTable('up');
      return;
    }
    const currentIndex = tableUIDs.findIndex(x => x === lastVisibleTable);
    const prevTable = tableUIDs[currentIndex - 1];
    if (prevTable) {
      document.getElementById(prevTable).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleScrollDown = () => {
    if (shouldDisableScrollDown) return;
    if (selectedTable) {
      moveTable('down');
      return;
    }
    const currentIndex = tableUIDs.findIndex(x => x === lastVisibleTable);
    const nextTable = tableUIDs[currentIndex + 1];
    if (nextTable) {
      document.getElementById(nextTable).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleFiltersClick = () => {
    if (selectedTable) {
      setIsFiltersOpen(!isFiltersOpen);
    }
  };

  const handleFilterSelect = (id) => {
    if (!selectedTable) return;
    const currentTableSettings = tablesSettings[selectedTable];
    
    if (id === 'all') {
      if (currentTableSettings.filters.storeGroupId) {
        updateFilters(selectedTable, {
          ...currentTableSettings.filters,
          storeGroupId: null
        });
      } else {
        updateFilters(selectedTable, {
          ...currentTableSettings.filters,
          storeGroupId: null
        });
      }
      return;
    }

    updateFilters(selectedTable, {
      ...currentTableSettings.filters,
      storeGroupId: id === currentTableSettings.filters.storeGroupId ? null : id
    });
  };

  const handleSettingsClick = () => {
    if (selectedTable) {
      toggleEditing();
    }
  };

  return (
    <div className={`side-menu side-menu--dashboard ${isMenuHidden ? 'hidden' : ''}`}>
      <ExportProgress progress={progress} />
      <div className="controls-group">
        <div 
          className={`control-item ${!selectedTable ? 'disabled' : ''} ${isFiltersOpen ? 'expanded' : ''}`}
          onClick={handleFiltersClick}
        >
          <div className="menu-item-content">
            <FilterNew />
            <span>{t('adminTable.controller.filters')}</span>
          </div>
          
          {isFiltersOpen && selectedTable && (
            <div className="submenu">
              {categories.map((category) => {
                const currentFilters = tablesSettings[selectedTable]?.filters;
                const isAllSelected = !currentFilters?.storeGroupId;
                return (
                  <div
                    key={category.id}
                    className={`menu-option ${
                      category.id === 'all' 
                        ? isAllSelected ? 'selected' : ''
                        : currentFilters?.storeGroupId === category.id ? 'selected' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFilterSelect(category.id);
                    }}
                  >
                    {getFilterLabel(category)}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div 
          className={`control-item ${!selectedTable ? 'disabled' : ''} ${isEditing ? 'expanded' : ''}`}
          onClick={handleSettingsClick}
        >
          <div className="menu-item-content">
            <Settings />
            <span>{t('adminTable.controller.settings')}</span>
          </div>
        </div>

        <div 
          className={`control-item ${disableExport}`}
          onClick={handleExport}
        >
          <div className="menu-item-content">
            <ExportNew />
            <span>{t('adminTable.controller.export')}</span>
          </div>
        </div>

        <div 
          className={`control-item ${isAddDisabled ? 'disabled' : ''}`}
          onClick={handleAdd}
        >
          <div className="menu-item-content">
            <Add />
            <span>{t('adminTable.controller.add')}</span>
          </div>
        </div>

        <div 
          className={`control-item ${disableIfNoTable}`}
          onClick={handleDelete}
        >
          <div className="menu-item-content">
            <Delete />
            <span>{t('adminTable.controller.delete')}</span>
          </div>
        </div>

        <div 
          className={`control-item ${shouldDisableScrollUp ? 'disabled' : ''}`}
          onClick={handleScrollUp}
        >
          <div className="menu-item-content">
            <HideUp />
            <span>
              {selectedTable 
                ? t('adminTable.controller.moveUp')
                : t('adminTable.controller.scrollUp')
              }
            </span>
          </div>
        </div>

        <div 
          className={`control-item ${shouldDisableScrollDown ? 'disabled' : ''}`}
          onClick={handleScrollDown}
        >
          <div className="menu-item-content">
            <HideDown />
            <span>
              {selectedTable
                ? t('adminTable.controller.moveDown')
                : t('adminTable.controller.scrollDown')
              }
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSideMenu;